<template>
  <div class="ml-gioi-thieu container container-mobile mb-5">
    <div class="form-content">
      <div class="header d-flex nowrap overflow-auto">
        <span :class="{'active text-primary font-weight-bold': tabActive === 0}" @click="tabActive = 0">Về MobiLearn</span>
        <span :class="{'active text-primary font-weight-bold': tabActive === 1}" @click="tabActive = 1">Điều khoản <span class="d-none d-lg-inline-block" :class="{'text-primary font-weight-bold': tabActive === 1}"> sử dụng</span></span>
        <span :class="{'active text-primary font-weight-bold': tabActive === 2}" @click="tabActive = 2">Chính sách <span class="d-none d-lg-inline-block" :class="{'text-primary font-weight-bold': tabActive === 2}"> bảo mật dữ liệu cá nhân</span></span>
        <span :class="{'active text-primary font-weight-bold': tabActive === 3}" @click="tabActive = 3">Thỏa thuận <span class="d-none d-lg-inline-block" :class="{'text-primary font-weight-bold': tabActive === 3}"> cung cấp dịch vụ mạng xã hội</span></span>
      </div>
      <div class="content">
        <template v-if="tabActive === 0">
          <h4 class="font-weight-bold text-center">GIỚI THIỆU VỀ MOBILEARN</h4>
          <div class="title font-weight-bold">1. Giới thiệu dịch vụ</div>
          <div class="description text-justify text-break pt-3">
            MobiLearn – Kết nối tri thức mọi lúc mọi nơi là dịch vụ cung cấp nền tảng học tập trực tuyến cho học sinh từ tiểu học đến trung học phổ thông giúp học sinh dễ dàng tiếp cận nhiều nguồn kiến thức giáo dục chất lượng từ đội ngũ là các gia sư - giáo viên giỏi. Các thuê bao đăng ký dịch vụ
            được tương tác với gia sư và tham gia các khoá học bài giảng, kho tài liệu – đề thi online. Với lợi thế về công nghệ giúp tiết kiệm thời gian, chi phí MobiLearn chính là một môi trường học tập hiện đại, thuận tiện để các em học sinh cải tiến chất lượng học tập và đạt hiệu quả cao
          </div>
          <div class="description text-justify text-break pt-3">
            <strong>Thư viện khóa học, video bài giảng, bộ đề:</strong> Cung cấp kho thư viện nội dung lớn, đa dạng tất cả các môn và các khối lớp<br>
            &emsp;+ Thư viện video: Bài giảng ngắn (microlearning) được nhóm thành các chuyên đề - khóa học từ cơ bản đến nâng cao, được cập nhật và sản xuất mới. Tất cả các bài giảng được thiết kế bám sát theo chương trình học sách giáo khoa và nâng cao theo từng khối, lớp của K12. Bài giảng được
            biên soạn bởi đội ngũ giáo viên, chuyên gia hàng đầu với những hiệu ứng kĩ thuật, âm thanh, nội dung cập nhật, đưa đến những giá trị cho học sinh tham gia khóa học.<br>
            &emsp;+ Thư viện tài liệu: Ebook, tài liệu bộ đề, tài liệu tham khảo được biên soạn độc quyền, hợp tắc.<br>
            &emsp;+ Trắc nghiệm: Bộ bài thi trắc nghiệm online cho phép học sinh làm bài, xem kết quả với lời giải chi tiết<br>
            <strong>Hỏi đáp cùng gia sư:</strong><br>
            &emsp;+ Hỏi đáp gia sư: Cung cấp đội ngũ gia sư giúp trả lời các câu hỏi nhanh/chính xác cho học sinh. Đồng thời xây dựng các nhóm học tập có gia sư hỗ trợ.<br>
          </div>
        </template>
        <template v-if="tabActive === 1">
          <h4 class="font-weight-bold text-center">ĐIỀU KHOẢN SỬ DỤNG</h4>
          <div class="description text-justify text-break">Chào mừng bạn đến với MobiLearn!</div>
          <div class="description text-justify text-break">
            Bằng việc sử dụng dịch vụ của MobiLearn, bạn đang đồng ý với các điều khoản dưới đây. Vì vậy vui lòng đọc kỹ các điều khoản.
          </div>
          <div class="text-justify text-break font-weight-bold pt-3">
            1. Đối tượng áp dụng
          </div>
          <div class="text-justify text-break">
            Toàn bộ các thành viên của MobiLearn đều phải tuân thủ những điều khoản này. Bằng việc chọn “Tôi đồng ý với các Điều khoản sử dụng và Chính sách cá nhân của MobiLearn khi đăng ký Tài khoản, bạn đồng ý rằng bạn đã đọc, hiểu và đồng ý với những điều khoản được nêu tại đây.
          </div>
          <div class="text-justify text-break font-weight-bold pt-3">
            2. Trẻ em dưới 12 tuổi
          </div>
          <div class="text-justify text-break">
            MobiLearn được xây dựng để phục vụ cho mục đích giáo dục và học tập của học sinh phổ thông từ lớp 1 cho đến lớp 12 và trên lớp 12. Tuy nhiên, việc sử dụng MobiLearn của đối tượng trẻ em dưới 12 tuổi phải có sự đồng ý và giám sát của cha mẹ, gia đình hoặc người giám hộ.
          </div>
          <div class="text-justify text-break font-weight-bold pt-3">
            3. Đăng ký Thành viên
          </div>
          <div class="text-justify text-break">
            Để có thể sử dụng đầy đủ các tính năng của MobiLearn, bạn cần đăng ký tài khoản và cung cấp đầy đủ, chính xác các thông tin cá nhân. Điều này sẽ giúp MobiLearn dễ dàng hỗ trợ bạn khi cần thiết. Khi đăng ký tài khoản, điều đó đồng nghĩa với việc bạn đồng ý chia sẻ các thông tin cá nhân
            của bạn cho MobiLearn. Người dùng chịu mọi trách nhiệm khi cung cấp thông tin không chính xác, dẫn đến việc MobiLearn không hỗ trợ kịp thời hoặc không thể liên hệ được khi cần thiết.
          </div>
          <div class="text-justify text-break font-weight-bold pt-3">
            5. Sử dụng
          </div>
          <div class="text-justify text-break">
            Là một thành viên của MobiLearn, bạn đồng ý chỉ sử dụng các dịch vụ, tính năng và tiện ích mà MobiLearn cung cấp cho các mục đích phù hợp với pháp luật Việt Nam, không vi phạm các quy định của cơ quan, tổ chức, cá nhân nơi bạn đang học tập, công tác, sinh sống. Bạn đồng ý không sử dụng
            MobiLearn cho các mục đích thương mại, gian lận trong các kỳ thi, kỳ kiểm tra hoặc các mục đích phi giáo dục khác. Bạn đồng ý không phát tán hoặc truyền đạt các nội dung có chứa virus, malware hoặc tương tự có khả năng phá hoại, ăn cắp dữ liệu người dùng, ảnh hưởng đến hệ thống hoặc ảnh
            hưởng đến trải nghiệm của người dùng trong hệ thống. Bạn cũng đồng ý không sử dụng các công cụ tự động, robot, web automation…để khai thác thông tin, cướp quyền truy cập hoặc phá hoại hệ thống MobiLearn. MobiLearn có quyền xem, yêu cầu chỉnh sửa, điều chỉnh, xóa bỏ và ghi nhận toàn bộ
            các hoạt động của bạn trên MobiLearn mà không cần thông báo hoặc cho phép nhằm tối ưu hóa các nội dung tại MobiLearn để đảm bảo trải nghiệm tốt nhất cho người dùng, đảm bảo chất lượng của các nội dung cũng như ngăn chặn mọi sự hiểu nhầm về MobiLearn. Tất cả các thông tin, nội dung cung
            cấp tại MobiLearn đều được kiểm duyệt 24/24. MobiLearn có quyền khóa tài khoản của người sử dụng ngay khi có bất cứ nội dung nào vi phạm điều khoản sử dụng này.
          </div>
          <div class="text-justify text-break font-weight-bold pt-3">
            5. Nội dung
          </div>
          <div class="text-justify text-break">
            Người sử dụng MobiLearn là người chịu trách nhiệm đối với toàn bộ những nội dung, hình ảnh và các thông tin khác mà người sử dụng cung cấp, thông báo hoặc đăng tải thông qua các phương tiện của MobiLearn. MobiLearn không chịu trách nhiệm về mọi hậu quả của việc sử dụng các thông tin do
            người dùng đăng tải và cung cấp thông qua các phương tiện của MobiLearn. Người dùng sẽ không thể chỉnh sửa nội dung câu hỏi của mình khi đã có ít nhất một câu trả lời, cũng như không thể chỉnh sửa nội dung câu trả lời của mình khi câu trả lời được chọn là hay nhất hoặc câu trả lời đã
            được xác thực bởi MobiLearn. Trong lúc đang được kiểm duyệt bởi MobiLearn, người dùng cũng sẽ không thể chỉnh sửa các nội dung bao gồm câu hỏi, câu trả lời và bình luận của mình. Bằng việc cung cấp, thông báo, đăng tải các nội dung, hình ảnh, thông tin thông qua các phương tiện của
            MobiLearn, điều đó đồng nghĩa với việc bạn đảm bảo rằng mình có đầy đủ hành vi nhận thức, sự cho phép để cung cấp các nội dung một cách độc lập và chịu mọi trách nhiệm về các nội dung đó. Tất cả các nội dung của bạn không được chứa các nội dung mang tính spam, mang tính kích động, bạo
            lực, thù địch, gây rối trật tự, đồi trụy, các nội dung sai lệch, vi phạm pháp luật, xuyên tạc văn hóa, thuần phong mỹ tục, chống đối, phá hoại chính quyền, Đảng, Nhà nước và Pháp luật Việt Nam. Bạn không được phát tán thông tin cá nhân của người khác, bôi nhọ, nói xấu, hoặc có các hành
            vi làm ảnh hưởng đến trải nghiệm cũng như tâm lý, cuộc sống của người khác. Các nội dung của bạn không được chứa liên kết tới bên thứ ba, hoặc sao chép từ bên thứ ba, hoặc chứa các nội dung quảng cáo cho bên thứ ba. Nếu như cố tình vi phạm, bạn phải chịu hoàn toàn mọi trách nhiệm trước
            pháp luật Việt Nam. MobiLearn có cung cấp liên kết tới nhiều website khác không thuộc quyền quản lý của MobiLearn. Tuy nhiên MobiLearn không chịu trách nhiệm về nội dung thông tin và mọi hậu quả xảy ra liên quan tới việc sử dụng các địa chỉ, liên kết này.
          </div>
          <div class="text-justify text-break font-weight-bold pt-3">
            6. Thu thập thông tin
          </div>
          <div class="text-justify text-break">
            MobiLearn được quyền thu thập toàn bộ các thông tin mà người dùng cung cấp để sử dụng cho những mục đích cụ thể. MobiLearn có quyền nhưng không có nghĩa vụ phải giám sát, theo dõi và lưu trữ các nội dung, hoạt động của người dùng để bảo vệ sự an toàn của bạn và sự an toàn của những người
            khác. Bằng việc đăng ký tài khoản tại MobiLearn, bạn đồng ý với việc giám sát, theo dõi và lưu trữ nội dung này của chúng tôi. Các thông tin được thu thập nhằm phục vụ người dùng một cách tốt nhất, nhanh chóng hỗ trợ và giải đáp các thắc mắc, vấn đề mà người dùng gặp phải, cũng như cải
            tiến, nâng cao chất lượng dịch vụ. Thông qua các thông tin cá nhân của bạn, MobiLearn khi cần thiết có quyền gửi cho bạn các thông báo, các nội dung có liên quan mà bạn có thể quan tâm, cũng như liên hệ trực tiếp về các vấn đề kỹ thuật, bảo mật hoặc trải nghiệm khi sử dụng. Dữ liệu cá
            nhân của người dùng sẽ được lưu trữ cho đến khi có yêu cầu hủy bỏ hoặc tự người dùng đăng nhập và xóa bỏ các nội dung được lưu trữ. Trong những trường hợp còn lại thông tin cá nhân sẽ được bảo mật và lưu trữ tại hệ thống máy chủ của MobiLearn.
          </div>
          <div class="text-justify text-break font-weight-bold pt-3">
            7. Chia sẻ thông tin
          </div>
          <div class="text-justify text-break">
            Các thông tin của bạn sẽ được bảo mật và không được chia sẻ cho bên thứ ba, trừ các trường hợp sau đây:<br>
            - Yêu cầu của cơ quan quản lý có thẩm quyền hoặc phục vụ mục đích điều tra. <br>
            - Các thông tin (tên người dùng, số điểm, hạng, lớp, hoạt động…) cũng như các câu hỏi, câu trả lời, bình luận của bạn luôn xuất hiện trên MobiLearn, đồng thời có thể xuất hiện tại các công cụ tìm kiếm hoặc các nền tảng mạng xã hội mà MobiLearn sử dụng. <br>
            - Cơ quan chủ quản của MobiLearn và các đơn vị thành viên cùng chung cơ quan chủ quản đều có quyền sử dụng các thông tin này.<br>
          </div>
          <div class="text-justify text-break font-weight-bold pt-3">
            8. Bản quyền
          </div>
          <div class="text-justify text-break">
            Việc sử dụng các dịch vụ, tính năng và công cụ mà MobiLearn cung cấp không đồng nghĩa với việc MobiLearn chia sẻ cho bạn bản quyền, quyền tác giả, quyền sở hữu trí tuệ đối với MobiLearn và các dịch vụ, tính năng và công cụ được cung cấp. Facebook Platform, Facebook Page, Facebook
            Messenger thuộc bản quyền của Facebook. MobiLearn không có liên hệ nào khác ngoài việc là một đơn vị sử dụng các dịch vụ mà nền tảng này cung cấp.
          </div>
          <div class="text-justify text-break font-weight-bold pt-3">
            9. Thay đổi điều khoản
          </div>
          <div class="text-justify text-break">
            MobiLearn luôn thường xuyên theo dõi, cập nhật và điều chỉnh các điều khoản sử dụng. Những sự thay đổi có hiệu lực ngay khi được cập nhật tại trang Điều khoản sử dụng. Mọi thao tác của người dùng sẽ chịu ảnh hưởng của Điều khoản sử dụng cập nhật phiên bản mới nhất.
          </div>
          <div class="text-justify text-break font-weight-bold pt-3">
            10. Liên hệ
          </div>
          <div class="text-justify text-break">
            Trong trường hợp có bất cứ vấn đề gì phát sinh trong quá trình sử dụng (ngoại trừ các vấn đề liên quan câu hỏi, câu trả lời, bình luận và thời gian phản hồi), vui lòng liên hệ với MobiLearn thông qua email: <a href="mailto:colearn.telco@gmail.com" class="text-primary">colearn.telco@gmail.com</a>.
          </div>
        </template>
        <template v-if="tabActive === 2">
          <h4 class="font-weight-bold text-center">VĂN BẢN CHẤP THUẬN<br/>
            VỀ XỬ LÝ VÀ BẢO VỆ DỮ LIỆU CÁ NHÂN
          </h4>
          <div class="text-justify text-break">
            Khách hàng đồng ý với Văn bản chấp thuận về xử lý và bảo vệ dữ liệu cá nhân (“<b>Văn Bản</b>”) do Mobilearn.vn xây dựng để quy định việc xử lý Dữ liệu cá nhân và trách nhiệm bảo vệ Dữ liệu cá nhân của Mobilearn.vn với nội dung như sau:
          </div>
          <div class="text-justify text-break">
            <p>
              <strong>ĐIỀU 1.</strong> <strong>ĐỊNH NGHĨA</strong>
            </p>
            <p>
              1. <strong><em>Khách hàng/Quý khách:</em></strong>là (i) cá nhân và/hoặc
              (ii) tổ chức có cung cấp dữ liệu cá nhân mua, đăng ký sử dụng, sử dụng Sản
              phẩm, hàng hóa, dịch vụ của Mobilearn.vn.
            </p>
            <p>
              2. <strong><em>Sản phẩm, hàng hóa, dịch vụ</em></strong> là bất kỳ sản
              phẩm, hàng hóa, dịch vụ nào do Mobilearn.vn cung cấp và/hoặc do Mobilearn.vn
              hợp tác với đối tác mà Khách hàng mua, đăng ký sử dụng, sử dụng.
            </p>
            <p>
              3. <strong><em>Dữ liệu cá nhân:</em></strong> là thông tin dưới dạng ký
              hiệu, chữ viết, chữ số, hình ảnh, âm thanh hoặc dạng tương tự trên môi
              trường điện tử gắn liền với Khách hàng cụ thể hoặc giúp xác định Khách hàng
              cụ thể. Dữ liệu cá nhân bao gồm Dữ liệu cá nhân cơ bản và Dữ liệu cá nhân
              nhạy cảm.
            </p>
            <p>
              4. <strong><em>Dữ liệu cá nhân cơ bản</em></strong> là những thông tin bao
              gồm:
            </p>
            <p>
              (i) Họ, chữ đệm và tên khai sinh, tên gọi khác (nếu có);
            </p>
            <p>
              (ii) Ngày, tháng, năm sinh; ngày, tháng, năm chết hoặc mất tích;
            </p>
            <p>
              (iii) Giới tính;
            </p>
            <p>
              (iv) Nơi sinh, nơi đăng ký khai sinh, nơi thường trú, nơi tạm trú,
              nơi ở hiện tại, quê quán, địa chỉ liên hệ;
            </p>
            <p>
              (v) Quốc tịch;
            </p>
            <p>
              (vi) Hình ảnh của cá nhân;
            </p>
            <p>
              (vii) Số điện thoại, số chứng minh nhân dân, số căn cước công dân, số
              định danh cá nhân, số hộ chiếu, số giấy phép lái xe, số biển số xe, số mã
              số thuế cá nhân, số bảo hiểm xã hội, số thẻ bảo hiểm y tế;
            </p>
            <p>
              (viii) Thông tin về mối quan hệ gia đình (cha mẹ, con cái);
            </p>
            <p>
              (ix) Thông tin về tài khoản số của cá nhân;
            </p>
            <p>
              (x) Thông tin về hoạt động sử dụng dịch vụ viễn thông của khách
              hàng, bao gồm: thư điện tử; thông tin liên quan đến việc sử dụng dịch vụ
              thoại và tin nhắn (bao gồm nhưng không giới hạn số điện thoại khởi tạo cuộc
              gọi, số điện thoại nhận cuộc gọi, thời gian khởi tạo và kết thúc cuộc gọi,
              thời gian cuộc gọi, số điện thoại gửi tin nhắn, số điện thoại nhận tin
              nhắn, thời gian gửi và nhận tin nhắn); các gói cước đã đăng ký và thông tin
              cước (thông tin cước sử dụng, nợ cước, cước đóng trước...); phí dịch vụ,
              tiền cước; lịch sử sử dụng dữ liệu di động (sms, dịch vụ giá trị gia tăng,
              data...), thời gian sử dụng dữ liệu di động; dung lượng sử dụng, lịch sử và
              tần suất sử dụng Sản phẩm, hàng hóa, dịch vụ; địa chỉ IP; thông tin đánh
              giá Sản phẩm, hàng hóa, dịch vụ/khiếu nại/yêu cầu sửa chữa/xử lý sự cố;
            </p>
            <p>
              (xi) Dữ liệu cá nhân phản ánh hoạt động, lịch sử hoạt động trên
              không gian mạng, bao gồm nhưng không giới hạn ở các dữ liệu liên quan đến
              các Mạng xã hội/wapsite/mạng xã hội hoặc Ứng dụng; dữ liệu kỹ thuật (bao
              gồm loại thiết bị, hệ điều hành, loại trình duyệt, cài đặt trình duyệt, địa
              chỉ IP, cài đặt ngôn ngữ, ngày và giờ kết nối với Mạng xã hội, thống kê sử
              dụng Ứng dụng, cài đặt Ứng dụng, ngày và giờ kết nối với Ứng dụng, thông
              tin liên lạc kỹ thuật khác); tên tài khoản; mật khẩu; chi tiết đăng nhập
              bảo mật; dữ liệu sử dụng; dữ liệu cookie; lịch sử duyệt web; dữ liệu
              clickstream; lịch sử xem kênh, VOD (video theo yêu cầu);
            </p>
            <p>
              (xii) Các thông tin khác gắn liền với Khách hàng cụ thể hoặc giúp xác
              định Khách hàng cụ thể không thuộc phạm vi Dữ liệu cá nhân nhạy cảm theo
              quy định tại khoản 6 Điều này.
            </p>
            <p>
              5. <strong><em>Dữ liệu cá nhân nhạy cảm:</em></strong> là Dữ liệu cá nhân
              gắn liền với quyền riêng tư của Khách hàng mà khi bị xâm phạm sẽ gây ảnh
              hưởng trực tiếp tới quyền và lợi ích hợp pháp của Khách hàng, bao
              gồm<a name="_Hlk142557445">nhưng không giới hạn ở các loại dữ liệu sau</a>:
            </p>
            <p>
              (i) Thông tin tài khoản viễn thông của Khách hàng, bao gồm cả
              cả lịch sử thanh toán cước, nạp tiền vào tài khoản, số dư tài khoản viễn
              thông;
            </p>
            <p>
              (ii) Thông tin liên quan tới việc Khách hàng sử dụng dịch vụ trung
              gian thanh toán, bao gồm: thông tin định danh khách hàng theo quy định pháp
              luật, thông tin về tài khoản, thông tin về tiền gửi, thông tin về tài sản
              gửi, thông tin về giao dịch, thông tin về tổ chức, cá nhân là bên bảo đảm
              tại tổ chức tín dụng, chi nhánh ngân hàng, tổ chức cung ứng dịch vụ trung
              gian thanh toán;
            </p>
            <p>
              (iii) Thông tin liên quan đến nguồn gốc dân tộc, thuộc tính vật lý,
              đặc điểm sinh học riêng mà Khách hàng cung cấp cho Mobilearn.vn hoặc
              Mobilearn.vn có được trong quá trình Khách hàng mua, sử dụng Sản phẩm, hàng
              hóa, dịch vụ;
            </p>
            <p>
              (iv) Dữ liệu về vị trí của Khách hàng được xác định qua dịch vụ
              định vị;
            </p>
            <p>
              (v) Dữ liệu cá nhân khác được pháp luật quy định là đặc thù và
              cần có biện pháp bảo mật cần thiết.
            </p>
            <p>
              6. <strong><em>Xử lý Dữ liệu cá nhân:</em></strong> là một hoặc nhiều hoạt
              động tác động tới Dữ liệu cá nhân, như: thu thập, ghi, phân tích, xác nhận,
              lưu trữ, chỉnh sửa, công khai, kết hợp, truy cập, truy xuất, thu hồi, mã
              hóa, giải mã, sao chép, chia sẻ, truyền đưa, cung cấp, chuyển giao, xóa, hủy
              dữ liệu cá nhân hoặc các hành động khác có liên quan.
            </p>
            <p>
              7. <strong><em>Bên Kiểm soát Dữ liệu cá nhân:</em></strong> là tổ chức, cá
              nhân quyết định mục đích và phương tiện Xử lý Dữ liệu cá nhân.
            </p>
            <p>
              8. <strong><em>Bên Xử lý Dữ liệu cá nhân:</em></strong> là tổ chức, cá nhân
              thực hiện việc Xử lý Dữ liệu cá nhân thay mặt cho Bên Kiểm soát dữ liệu cá
              nhân, thông qua một hợp đồng hoặc thỏa thuận với Bên Kiểm soát dữ liệu cá
              nhân.
            </p>
            <p>
              9. <strong><em>Bên Kiểm soát và xử lý Dữ liệu cá nhân:</em></strong> là tổ
              chức, cá nhân đồng thời quyết định mục đích, phương tiện và trực tiếp xử lý
              Dữ liệu cá nhân.
            </p>
            <p>
              10. <strong><em>Kênh giao dịch của Mobilearn.vn:</em></strong> bao gồm kênh
              tổng đài, kênh giao dịch điện tử (<em>ứng dụng My Mobilearn.vn, Mạng xã hội
              như</em><a href="https://viettel.vn/"><em>https://mobilearn.vn.vn/</em></a><em>,
              wapsite, mạng xã hội</em>)
            </p>
            <p>
              <strong>ĐIỀU 2.</strong> <strong>LOẠI DỮ LIỆU XỬ LÝ</strong>
            </p>
            <p>
              1. Dữ liệu cá nhân được xử lý gồm các dữ liệu Khách hàng cung
              cấp cho Mobilearn.vn khi mua, sử dụng sản phẩm, hàng hóa, dịch vụ và dữ
              liệu phát sinh trong quá trình Khách hàng mua, sử dụng sản phẩm, hàng hóa,
              dịch vụ của Mobilearn.vn như sau:
            </p>
            <p>
              (i) Các Dữ liệu cá nhân cơ bản quy định tại khoản 5 Điều 1 của Văn Bản này;
              và
            </p>
            <p>
              (ii) Các Dữ liệu cá nhân nhạy cảm quy định khoản 6 Điều 1 của Văn Bản này.
            </p>
            <p>
              2. Dữ liệu cá nhân được xử lý phù hợp với từng loại Sản phẩm,
              hàng hóa, dịch vụ tương ứng.
            </p>
            <p>
              <strong>ĐIỀU 3.</strong> <strong>MỤC ĐÍCH XỬ LÝ DỮ LIỆU CÁ NHÂN</strong>
            </p>
            <p>
              Dữ liệu cá nhân theo Điều 2 trên đây có thể được xử lý cho các mục đích
              sau:
            </p>
            <p>
              1. Cung cấp Sản phẩm, hàng hóa, dịch vụ cho Khách hàng theo
              Hợp đồng và thực hiện quyền, nghĩa vụ của Mobilearn.vn theo quy định pháp
              luật, bao gồm nhưng không giới hạn:
            </p>
            <p>
              (i) Phục vụ hoạt động quản lý nghiệp vụ; vận hành, khai thác,
              tối ưu chất lượng mạng, dịch vụ di động, truyền hình, Internet và các dịch
              vụ khác do Mobilearn.vn cung cấp; xử lý sự cố mạng lưới; cung cấp, nâng cao
              chất lượng dịch vụ viễn thông, truyền hình, công nghệ thông tin của
              Mobilearn.vn;
            </p>
            <p>
              (ii) Xác thực và thực hiện các giao dịch thanh toán; đối soát
              cước, thanh toán cước; trao đổi cung cấp thông tin liên quan đến người sử
              dụng dịch vụ viễn thông với các doanh nghiệp viễn thông khác để phục vụ cho
              việc tính giá cước, lập hóa đơn và ngăn chặn hành vi trốn tránh thực hiện
              nghĩa vụ theo hợp đồng trong phạm vi pháp luật cho phép; đối soát với các
              đối tác hợp tác của Mobilearn.vn nhằm mục đích cung cấp Sản phẩm, hàng hóa,
              dịch vụ cho Khách hàng;
            </p>
            <p>
              (iii) Cung cấp, kích hoạt hoặc xác minh Sản phẩm, hàng hóa, dịch vụ
              mà Khách hàng yêu cầu theo Phiếu yêu cầu/Hợp đồng hoặc qua Kênh giao dịch
              của Mobilearn.vn hoặc các yêu cầu khác của Khách hàng phát sinh trong quá
              trình sử dụng Sản phẩm, hàng hóa, dịch vụ;
            </p>
            <p>
              (iv) Phục vụ cho mục đích liên hệ, thông báo với Khách hàng;
            </p>
            <p>
              (v) Thực hiện các quyền của Khách hàng liên quan đến Dữ liệu cá
              nhân theo quy định pháp luật, thỏa thuận giữa Khách hàng và Mobilearn.vn;
            </p>
            <p>
              (vi) Thực hiện nghĩa vụ theo hợp đồng của Khách hàng và nghĩa vụ
              của Mobilearn.vn với cơ quan, tổ chức, cá nhân có liên quan theo quy định
              pháp luật;
            </p>
            <p>
              (vii) Công khai Dữ liệu cá nhân của Khách hàng theo quy định pháp
              luật;
            </p>
            <p>
              (viii) Thực hiện các nghĩa vụ của Mobilearn.vn về thanh tra, kiểm tra,
              thống kê, báo cáo, tài chính, kế toán và thuế;
            </p>
            <p>
              (ix) Thực hiện các nghiệp vụ bảo đảm an toàn dữ liệu; bảo đảm an
              toàn hệ thống thông tin của Mobilearn.vn như sao lưu, dự phòng, giám sát,
              tối ưu tài nguyên và bảo vệ Dữ liệu cá nhân khách hàng;
            </p>
            <p>
              (x) Nhắn tin thông báo, truyền thông, vận động, ủng hộ liên quan
              đến Cổng thông tin nhân đạo quốc gia và theo yêu cầu của cơ quan quản lý
              nhà nước;
            </p>
            <p>
              (xi) Phát hiện, ngăn chặn các hành vi vi phạm pháp luật trên không
              gian mạng theo yêu cầu của cơ quan nhà nước có thẩm quyền và quy định pháp
              luật, bao gồm nhưng không giới hạn: tin nhắn rác, thư điện tử rác, cuộc gọi
              rác, tin nhắn có mục đích lừa đảo, thư điện tử có mục đích lừa đảo, cuộc
              gọi có mục đích lừa đảo;
            </p>
            <p>
              (xii) Phát hiện, ngăn chặn các hành vi gian lận, lừa đảo, tấn công,
              xâm nhập, chiếm đoạt trái phép, hành vi mang tính chất tội phạm và các hành
              vi bất hợp pháp khác;
            </p>
            <p>
              (xiii) Thực hiện các hoạt động có mục đích kiểm toán, quản lý rủi ro,
              phòng chống rửa tiền, tài trợ khủng bố và tuân thủ cấm vận;
            </p>
            <p>
              (xiv) Trong trường hợp tình trạng khẩn cấp về quốc phòng, an ninh quốc
              gia, trật tự an toàn xã hội, thảm họa lớn, dịch bệnh nguy hiểm; khi có nguy
              cơ đe dọa an ninh, quốc phòng nhưng chưa đến mức ban bố tình trạng khẩn
              cấp; phòng, chống bạo loạn, khủng bố, phòng, chống tội phạm và vi phạm pháp
              luật theo quy định pháp luật;
            </p>
            <p>
              (xv) Phục vụ hoạt động của cơ quan nhà nước theo quy định pháp luật
              chuyên ngành.
            </p>
            <p>
              2. Hỗ trợ Khách hàng khi mua, sử dụng Sản phẩm, hàng hóa, dịch
              vụ do Mobilearn.vn cung cấp theo hợp đồng và quy định pháp luật, bao gồm:
            </p>
            <p>
              (i) Cập nhật, xử lý thông tin khi Khách hàng mua, sử dụng Sản
              phẩm, hàng hóa, dịch vụ;
            </p>
            <p>
              (ii) Chăm sóc Khách hàng, tiếp nhận và giải quyết thắc mắc, khiếu
              nại của Khách hàng đối với các Sản phẩm, hàng hóa, dịch vụ của Mobilearn.vn;
            </p>
            <p>
              (iii) Sử dụng, chuyển giao cho đối tác các Dữ liệu cá nhân, thông
              tin vướng mắc, sự cố, báo cáo lỗi do Khách hàng phản ánh để xác định và
              khắc phục sự cố của Sản phẩm, hàng hóa, dịch vụ; sửa chữa thiết bị của Khách
              hàng; thực hiện hoạt động khác về chăm sóc và hỗ trợ Khách hàng;
            </p>
            <p>
              3. Nâng cao chất lượng Sản phẩm, hàng hóa, dịch vụ mà
              Mobilearn.vn cung cấp cho Khách hàng:
            </p>
            <p>
              (i) Cung cấp thông tin mà Khách hàng đã yêu cầu hoặc
              Mobilearn.vn cho rằng Khách hàng có thể thấy hữu ích, bao gồm thông tin về
              các Sản phẩm, hàng hóa, dịch vụ của Mobilearn.vn theo quy định pháp luật;
            </p>
            <p>
              (ii) Cải tiến công nghệ, giao diện Mạng xã hội, wapsite, mạng xã
              hội, Ứng dụng đảm bảo tiện lợi cho Khách hàng;
            </p>
            <p>
              (iii) Quản lý tài khoản Khách hàng và các chương trình Khách hàng
              thân thiết;
            </p>
            <p>
              (iv) Lưu trữ thông tin, nghiên cứu thị trường, phân tích, thống kê
              và các hoạt động quản lý nội bộ khác nhằm nâng cao trải nghiệm Khách hàng;
            </p>
            <p>
              (v) Báo cáo, thống kê, phân tích dữ liệu nội bộ để nghiên cứu,
              xây dựng, phát triển, quản lý, đo lường, cung cấp và cải tiến Sản phẩm,
              hàng hóa, dịch vụ cũng như điều hành hoạt động kinh doanh của Mobilearn.vn;
            </p>
            <p>
              (vi) Xây dựng chiến dịch tiếp thị Sản phẩm, hàng hóa, dịch vụ và
              xác định cách Mobilearn.vn có thể cá nhân hóa các sản phẩm, hàng hóa, dịch
              vụ đó;
            </p>
            <p>
              (vii) Phát triển, cung cấp Sản phẩm, hàng hóa, dịch vụ mới được cá
              nhân hóa theo nhu cầu, điều kiện thực tế của Khách hàng với phương pháp đo
              lường hiệu quả;
            </p>
            <p>
              (viii) Giới thiệu, cung cấp các chương trình khuyến mại cho Sản phẩm,
              hàng hóa, dịch vụ, ưu đãi, khuyến mại của Mobilearn.vn và của Mobilearn.vn
              hợp tác với đối tác;
            </p>
            <p>
              (ix) Đánh giá khả năng mua, sử dụng Sản phẩm, hàng hóa, dịch vụ của
              Mobilearn.vn thông qua điểm xếp hạng viễn thông của Khách hàng nhằm hỗ trợ
              tốt nhất trong việc cung cấp các sản phẩm, hàng hóa, dịch vụ cho Khách
              hàng;
            </p>
            <p>
              <a name="dieu_21">
                (i) Kinh doanh dịch vụ tiếp thị, quảng cáo, giới thiệu sản
                phẩm
              </a>
              phù hợp với nhu cầu của Khách hàng hoặc Mobilearn.vn cho rằng Khách hàng
              quan tâm theo nội dung, hình thức, tần suất như sau:
            </p>
            <p>
              (i) Nội dung: Giới thiệu thông tin các sản phẩm, hàng hóa, dịch
              vụ, ưu đãi do Mobilearn.vn và đối tác của Mobilearn.vn cung cấp;
            </p>
            <p>
              (ii) Phương thức: Qua tin nhắn quảng cáo (SMS, USSD, MMS…), cuộc
              gọi IVR, thông báo trên Kênh giao dịch của Mobilearn.vn hoặc các phương
              thức khác theo quy định pháp luật;
            </p>
            <p>
              (iii) Hình thức: Gửi trực tiếp cho Khách hàng qua thiết bị, phương
              tiện điện tử hoặc các hình thức khác trên không gian mạng theo quy định
              pháp luật;
            </p>
            <p>
              (iv) Tần suất: Theo quy định pháp luật về quảng cáo.
            </p>
            <p>
              4. Kinh doanh dịch vụ nghiên cứu thị trường, thăm dò dư luận,
              môi giới.
            </p>
            <p>
              5. Tổ chức giới thiệu và xúc tiến thương mại.
            </p>
            <p>
              <strong>ĐIỀU 4.</strong>
              <strong>
                XỬ LÝ DỮ LIỆU CỦA KHÁCH HÀNG DƯỚI 16 TUỔI
              </strong>
            </p>
            <p>
              1. Trong quá trình cung cấp Sản phẩm, hàng hóa, dịch vụ,
              Mobilearn.vn sẽ có các Khách hàng là cá nhân trong độ tuổi từ đủ 7 (bảy)
              tuổi đến dưới 16 (mười sáu) tuổi (“<strong>Khách hàng trẻ em</strong>”).
              Mobilearn.vn sẽ tiến hành các quy trình cần thiết để xác minh độ tuổi của
              Khách hàng trẻ em trước khi xử lý Dữ liệu cá nhân.
            </p>
            <p>
              2. Trong trường hợp Khách hàng trẻ em mua, đăng ký sử dụng, sử
              dụng Sản phẩm, hàng hóa, dịch vụ, bảng nội dung tại Phụ lục 01 của Văn Bản
              này sẽ do Khách hàng trẻ em đánh dấu xác nhận. Cha, mẹ hoặc người giám hộ
              của Khách Hàng trẻ em sẽ đánh dấu xác nhận thể hiện sự đồng ý liên quan đến
              việc xử lý Dữ liệu cá nhân của Khách hàng trẻ em theo nội dung tại Phụ lục
              02 của Văn Bản này, trừ trường hợp pháp luật có quy định khác.
            </p>
            <p>
              <strong>ĐIỀU 5.</strong> <strong>CÁCH THỨC XỬ LÝ DỮ LIỆU</strong>
            </p>
            <p>
              1. <strong>Cách thức thu thập</strong>
            </p>
            <p>
              Dữ liệu cá nhân được thu thập trực tiếp từ Quý khách trong các trường hợp
              sau:
            </p>
            <p>
              (i) <strong>Từ website của Mobilearn.vn:</strong> Chúng tôi có thể thu thập
              Dữ liệu cá nhân khi Quý khách truy cập bất kỳ Mạng xã hội nào của
              Mobilearn.vn (gọi chung là “Mạng xã hội”) hoặc sử dụng bất kỳ tính năng,
              tài nguyên nào có sẵn trên hoặc thông qua Mạng xã hội. Khi Quý khách truy
              cập Mạng xã hội, Mobilearn.vn thu thập thông tin về thiết bị và trình duyệt
              của Khách hàng (chẳng hạn như loại thiết bị, hệ điều hành, loại trình
              duyệt, cài đặt trình duyệt, địa chỉ IP, cài đặt ngôn ngữ, ngày và giờ kết
              nối với Mạng xã hội và các thông tin liên lạc kỹ thuật khác);
            </p>
            <p>
              (ii) <strong>Từ Ứng dụng:</strong>Chúng tôi có thể thu thập Dữ liệu cá nhân
              khi Khách hàng tải xuống hoặc sử dụng ứng dụng dành cho thiết bị di động
              (gọi chung là “Ứng dụng”). Các Ứng dụng này có thể ghi lại một số thông tin
              nhất định (bao gồm thống kê sử dụng Ứng dụng, loại thiết bị, hệ điều hành,
              cài đặt Ứng dụng, địa chỉ IP, cài đặt ngôn ngữ, ngày và giờ kết nối với Ứng
              dụng và các thông tin liên lạc kỹ thuật khác);
            </p>
            <p>
              (iii) <strong>Từ các Sản phẩm, hàng hóa, dịch vụ:</strong> Mobilearn.vn có
              thể thu thập Dữ liệu cá nhân khi Quý khách mua, đăng ký sử dụng, sử dụng
              bất kỳ Sản phẩm, hàng hóa, dịch vụ nào thông qua bất kỳ hình thức nào (SMS,
              USSD, IVR, website, wapsite, ứng dụng…); Kênh giao dịch của Mobilearn.vn,
              không gian mạng; và/hoặc các phương thức khác theo quy định pháp luật;
            </p>
            <p>
              (iv) <strong>Từ các trao đổi, liên lạc với Khách hàng:</strong> Chúng tôi
              có thể thu thập Dữ liệu cá nhân thông qua tương tác giữa Chúng tôi và Quý
              khách (gặp trực tiếp, qua thư, điện thoại, trực tuyến, hệ thống tổng đài,
              liên lạc điện tử hoặc bất kỳ phương tiện nào khác) bao gồm cả các cuộc khảo
              sát Khách hàng;
            </p>
            <p>
              (v) <strong>Từ mạng xã hội:</strong> Là các mạng xã hội của Mobilearn.vn
              và/hoặc các mạng xã hội do chúng tôi hợp tác với các đối tác;
            </p>
            <p>
              (vi) <strong>Từ các thiết bị ghi âm, ghi hình</strong>được đặt tại các cửa
              hàng, điểm kinh doanh hoặc nơi thực hiện một phần hoặc toàn bộ hoạt động
              kinh doanh của Mobilearn.vn mà Quý khách gặp, xuất hiện hoặc tương tác với
              chúng tôi. Việc đặt các thiết bị ghi âm, ghi hình nhằm mục đích góp phần
              bảo vệ trật tự an toàn xã hội, bảo vệ quyền và lợi ích hợp pháp của Khách
              hàng và của Mobilearn.vn theo quy định pháp luật;
            </p>
            <p>
              (vii)
              <strong>
                Từ các tương tác hoặc các công nghệ thu thập dữ liệu tự động:
              </strong>
              Chúng tôi có thể thu thập thông tin bao gồm địa chỉ IP, URL giới thiệu, hệ
              điều hành, trình duyệt tin điện tử và bất kỳ thông tin nào khác được ghi tự
              động từ kết nối:
            </p>
            <p>
              + Cookie, flash cookie, plug-in, thẻ pixel, tin điện tử beacons,
              trình kết nối mạng xã hội của bên thứ ba hoặc các công nghệ theo dõi khác;
            </p>
            <p>
              + Bất kỳ công nghệ nào có khả năng theo dõi hoạt động cá nhân trên
              các thiết bị hoặc Mạng xã hội;
            </p>
            <p>
              + Thông tin dữ liệu khác được cung cấp bởi một thiết bị.
            </p>
            <p>
              (viii) <strong>Các phương tiện khác:</strong> Chúng tôi có thể thu thập Dữ
              liệu cá nhân khi Quý khách tương tác với Mobilearn.vn thông qua bất kỳ
              phương tiện nào khác.
            </p>
            <p>
              Chúng tôi cũng có thể thu thập Dữ liệu cá nhân gián tiếp từ Quý khách thông
              qua các nguồn thông tin công khai, chính thống; hoặc thông qua việc nhận
              chia sẻ dữ liệu cần thiết từ các công ty con, đối tác mà họ thu thập được
              trong quá trình hợp tác với Mobilearn.vn cung cấp Sản phẩm, hàng hóa, dịch
              vụ cho Quý khách và được Quý khách cho phép chia sẻ.
            </p>
            <p>
              2. <strong>Cách thức lưu trữ</strong>
            </p>
            <p>
              Dữ liệu cá nhân được lưu trữ tại Việt Nam tại hệ thống cơ sở dữ liệu Khách
              hàng của Mobilearn.vn hoặc tại bất cứ đâu mà chúng tôi hoặc các chi nhánh,
              công ty con, công ty liên kết, đối tác hoặc nhà cung cấp dịch vụ của chúng
              tôi có cơ sở và tạo bản sao lưu trữ cho trung tâm dữ liệu ở một khu vực
              khác.
            </p>
            <p>
              Trong quá trình Quý khách truy cập Mạng xã hội, wapsite, Ứng dụng, mạng xã
              hội của Mobilearn.vn, chúng tôi cũng có thể lưu trữ thông tin tạm thời qua
              cookie hoặc các công cụ lưu trữ dữ liệu duyệt website tương tự để lưu trữ
              những dữ liệu mà máy chủ web trong miền có thể truy lại.
            </p>
            <p>
              3. <strong>Cách thức chuyển giao/chia sẻ dữ liệu</strong>
            </p>
            <p>
              Chúng tôi sẽ sử dụng các biện pháp bảo mật cần thiết để đảm bảo việc chuyển
              giao/chia sẻ Dữ liệu cá nhân của Quý khách đến (i) các doanh nghiệp viễn
              thông khác; (ii) cá nhân/tổ chức tham gia quá trình Xử lý Dữ liệu cá nhân
              quy định tại Điều 9 Văn Bản này); hoặc (iii) cơ quan nhà nước có thẩm
              quyền, đảm bảo an toàn thông tin, không bị lộ, lọt dữ liệu và yêu cầu các
              bên tiếp nhận Dữ liệu cá nhân sẽ có biện pháp bảo mật dữ liệu.
            </p>
            <p>
              4. <strong>Cách thức phân tích</strong>
            </p>
            <p>
              Việc phân tích Dữ liệu cá nhân được thực hiện theo các quy trình nội bộ của
              Mobilearn.vn. Chúng tôi luôn có cơ chế giám sát nghiêm ngặt từng quy trình
              phân tích dữ liệu, trong đó yêu cầu kiểm tra việc đáp ứng các yêu cầu của
              pháp luật về bảo mật dữ liệu, bảo đảm an toàn thông tin đối với hệ thống
              công nghệ thông tin trước khi tiến hành phân tích. Chúng tôi cũng có các
              quy tắc nghiêm ngặt đảm bảo rằng thông tin cá nhân được ẩn danh hoặc hủy
              nhận dạng ở giai đoạn thích hợp trong quá trình xử lý.
            </p>
            <p>
              5. <strong>Cách thức mã hóa</strong>
            </p>
            <p>
              Dữ liệu cá nhân thu thập được mã hóa theo các tiêu chuẩn mã hóa phù hợp khi
              cần thiết trong quá trình lưu trữ hoặc chuyển giao dữ liệu, để đảm bảo các
              dữ liệu được bảo vệ, xác thực, toàn vẹn và không thể bị thay đổi sau khi đã
              được gửi đi.
            </p>
            <p>
              6. <strong>Cách thức xóa dữ liệu</strong>
            </p>
            <p>
              Khi Quý khách chấm dứt sử dụng Sản phẩm, hàng hóa, dịch vụ của Mobilearn.vn
              và có yêu cầu hợp lệ, chúng tôi sẽ tiến hành xóa Dữ liệu cá nhân với toàn
              bộ Dữ liệu cá nhân mà Quý khách đã cung cấp và/hoặc chúng tôi thu thập được
              trong quá trình Quý khách sử dụng Sản phẩm, hàng hóa, dịch vụ, trừ trường
              hợp pháp luật có quy định khác và một số trường hợp không thể thực hiện
              được như sau:
            </p>
            <p>
              (i) Pháp luật quy định không cho phép xóa dữ liệu hoặc yêu cầu
              bắt buộc phải lưu trữ dữ liệu;
            </p>
            <p>
              (ii) Dữ liệu cá nhân được xử lý bởi cơ quan nhà nước có thẩm quyền
              với mục đích phục vụ hoạt động của cơ quan nhà nước theo quy định pháp
              luật;
            </p>
            <p>
              (iii) Dữ liệu cá nhân đã được công khai theo quy định pháp luật;
            </p>
            <p>
              (iv) Dữ liệu cá nhân được xử lý nhằm phục vụ yêu cầu pháp lý, nghiên
              cứu khoa học, thống kê theo quy định pháp luật;
            </p>
            <p>
              (v) Trong trường hợp tình trạng khẩn cấp về quốc phòng, an ninh
              quốc gia, trật tự an toàn xã hội, thảm họa lớn, dịch bệnh nguy hiểm; khi có
              nguy cơ đe dọa an ninh, quốc phòng nhưng chưa đến mức ban bố tình trạng khẩn
              cấp; phòng, chống bạo loạn, khủng bố, phòng, chống tội phạm và vi phạm pháp
              luật;
            </p>
            <p>
              (vi) Ứng phó với tình huống khẩn cấp đe dọa đến tính mạng, sức khỏe hoặc
              sự an toàn của Khách hàng hoặc cá nhân khác.
            </p>
            <p>
              <strong>ĐIỀU 6.</strong>
              <strong>
                NGUYÊN TẮC BẢO MẬT DỮ LIỆU CỦA MOBILEARN.VN
              </strong>
            </p>
            <p>
              1. Dữ liệu cá nhân của Quý khách được bảo mật theo Văn Bản này
              và quy định pháp luật.
            </p>
            <p>
              2. Chúng tôi có trách nhiệm bảo đảm an toàn thông tin mạng
              theo quy định pháp luật, bảo đảm quốc phòng, an ninh quốc gia, bí mật nhà
              nước, giữ vững ổn định chính trị, trật tự, an toàn xã hội và thúc đẩy phát
              triển kinh tế - xã hội.
            </p>
            <p>
              3. Việc xử lý sự cố an toàn thông tin mạng khi thực hiện luôn
              bảo đảm quyền và lợi ích hợp pháp của Quý khách.
            </p>
            <p>
              4. Hoạt động đảm bảo an toàn thông tin mạng được thực hiện
              thường xuyên, liên tục, kịp thời và hiệu quả.
            </p>
            <p>
              <strong>ĐIỀU 7.</strong>
              <strong>
                HẬU QUẢ, THIỆT HẠI KHÔNG MONG MUỐN CÓ KHẢ NĂNG XẢY RA
              </strong>
            </p>
            <p>
              1. Chúng tôi sử dụng nhiều công nghệ bảo mật thông tin khác
              nhau như: chuẩn quốc tế PCI, SSL, tường lửa, mã hóa… nhằm bảo vệ và ngăn
              chặn việc Dữ liệu cá nhân của Quý khách bị truy cập, sử dụng hoặc chia sẻ
              ngoài ý muốn. Tuy nhiên, không một dữ liệu nào có thể được bảo mật hoàn
              toàn. Do vậy, chúng tôi không thể cam kết bảo mật một cách tuyệt đối Dữ
              liệu cá nhân của Quý khách trong một số trường hợp như:
            </p>
            <p>
              (i) Lỗi phần cứng, phần mềm trong quá trình xử lý dữ liệu làm
              mất dữ liệu của Khách hàng;
            </p>
            <p>
              (ii) Lỗ hổng bảo mật nằm ngoài khả năng kiểm soát của chúng tôi,
              hệ thống bị hacker tấn công gây lộ lọt dữ liệu.
            </p>
            <p>
              2. Chúng tôi khuyến cáo Quý khách bảo mật các thông tin liên
              quan đến mật khẩu đăng nhập vào tài khoản của Quý khách, mã OTP và không
              chia sẻ nội dung này với bất kỳ người nào khác.
            </p>
            <p>
              3. Quý khách cần biết rõ rằng bất kỳ thời điểm nào Quý khách
              tiết lộ và công khai Dữ liệu cá nhân của Quý khách, dữ liệu đó có thể bị
              người khác thu thập và sử dụng bởi các mục đích nằm ngoài tầm kiểm soát của
              Quý khách và chúng tôi.
            </p>
            <p>
              4. Chúng tôi khuyến cáo Quý khách bảo quản thiết bị cá nhân
              (máy điện thoại, máy tính bảng, máy tính cá nhân…) trong quá trình sử dụng;
              Quý khách nên đăng xuất khỏi tài khoản của mình khi không có nhu cầu sử
              dụng.
            </p>
            <p>
              5. Trong trường hợp máy chủ lưu trữ dữ liệu bị tấn công dẫn
              đến bị mất, lộ, lọt Dữ liệu cá nhân của Khách hàng, Mobilearn.vn sẽ có
              trách nhiệm thông báo vụ việc cho cơ quan chức năng điều tra xử lý kịp thời
              và thông báo cho Quý khách được biết theo quy định pháp luật.
            </p>
            <p>
              6. Không gian mạng không phải là một môi trường an toàn và
              chúng tôi không thể đảm bảo tuyệt đối rằng Dữ liệu cá nhân của Quý khách
              được chia sẻ qua không gian mạng sẽ luôn được bảo mật. Khi Quý khách truyền
              tải Dữ liệu cá nhân qua không gian mạng, Quý khách chỉ nên sử dụng các hệ
              thống an toàn để truy cập Mạng xã hội, ứng dụng hoặc thiết bị. Quý khách có
              trách nhiệm giữ thông tin xác thực truy cập của mình cho từng Mạng xã hội,
              ứng dụng hoặc thiết bị an toàn và bí mật.
            </p>
            <p>
              <strong>ĐIỀU 8.</strong><strong>THỜI GIAN BẮT ĐẦU, THỜI GIAN KẾT THÚC</strong>
              <strong>XỬ LÝ DỮ LIỆU CÁ NHÂN</strong>
            </p>
            <p>
              1. Dữ liệu cá nhân được xử lý kể từ thời điểm chúng tôi nhận được Dữ
              liệu cá nhân do Quý khách cung cấp và chúng tôi đã có cơ sở pháp lý phù hợp
              để xử lý dữ liệu theo quy định pháp luật.
            </p>
            <p>
              2. Trong phạm vi pháp luật cho phép, Dữ liệu cá nhân sẽ được xử lý cho
              đến khi các mục đích xử lý dữ liệu đã được hoàn thành.
            </p>
            <p>
              3. Chúng tôi có thể phải lưu trữ Dữ liệu cá nhân của Quý khách ngay cả
              khi hợp đồng giữa Quý khách và Mobilearn.vn đã chấm dứt để thực hiện các
              nghĩa vụ pháp lý của chúng tôi theo quy định pháp luật và/hoặc yêu cầu của
              cơ quan nhà nước có thẩm quyền.
            </p>
            <p>
              <strong>ĐIỀU 9.</strong>
              <strong>
                TỔ CHỨC, CÁ NHÂN THAM GIA QUÁ TRÌNH XỬ LÝ DỮ LIỆU CÁ NHÂN
              </strong>
            </p>
            <p>
              1. Tùy từng trường hợp, chúng tôi có thể là Bên Kiểm soát Dữ
              liệu cá nhân hoặc Bên Kiểm soát và xử lý Dữ liệu cá nhân.
            </p>
            <p>
              2. Trong phạm vi pháp luật cho phép, Quý khách hiểu rõ rằng
              chúng tôi có thể chia sẻ Dữ liệu cá nhân nhằm các mục đích được đề cập tại
              Điều 3 với các tổ chức, cá nhân dưới đây:
            </p>
            <p>
              (i) Các công ty con, doanh nghiệp thành viên, công ty liên kết của
              Mobilearn.vn trong lãnh thổ Việt Nam;
            </p>
            <p>
              (ii) Tổ chức, cá nhân cung cấp dịch vụ và/hoặc hợp tác với Mobilearn.vn,
              bao gồm nhưng không giới hạn: đại lý, các đơn vị tư vấn, kiểm toán, luật
              sư, công chứng viên và các đối tác hợp tác kinh doanh, cung cấp cung cấp
              giải pháp công nghệ thông tin, phần mềm, ứng dụng, các dịch vụ vận hành,
              quản lý, xử lý sự cố, phát triển hạ tầng;
            </p>
            <p>
              (iii) Bất kỳ cá nhân, tổ chức nào là bên đại diện, bên được ủy quyền của
              Khách hàng, hành động thay mặt Khách hàng;
            </p>
            <p>
              (iv) Các nhà cung cấp dịch vụ thanh toán dựa trên ủy quyền hoặc chấp thuận
              của Khách hàng.
            </p>
            <p>
              Việc chia sẻ dữ liệu sẽ được thực hiện theo đúng trình tự, cách thức và quy
              định pháp luật hiện hành. Các bên tiếp nhận Dữ liệu cá nhân có nghĩa vụ bảo
              mật Dữ liệu cá nhân của Quý khách phù hợp với Văn Bản này; các quy định,
              quy trình, tiêu chuẩn về Bảo vệ Dữ liệu cá nhân của Mobilearn.vn và quy
              định pháp luật hiện hành.
            </p>
            <p>
              3. Chúng tôi có thể chia sẻ thông tin Quý khách với cơ quan
              nhà nước có thẩm quyền khi thực hiện mục đích Xử lý Dữ liệu cá nhân nêu tại
              Điều 3 Văn Bản này theo quy định pháp luật.
            </p>
            <p>
              <strong>ĐIỀU 10.</strong> <strong>QUYỀN CỦA KHÁCH HÀNG</strong>
            </p>
            <p>
              1. Quý khách được biết về hoạt động Xử lý Dữ liệu cá nhân của
              mình, trừ trường hợp pháp luật có quy định khác.
            </p>
            <p>
              2. Quý khách được quyết định sự đồng ý liên quan đến việc Xử
              lý Dữ liệu cá nhân của mình, trừ trường hợp pháp luật quy định khác.
            </p>
            <p>
              3. Quý khách được quyền truy cập để xem, chỉnh sửa hoặc yêu
              cầu chỉnh sửa Dữ liệu cá nhân của mình, trừ trường hợp pháp luật có quy
              định khác.
            </p>
            <p>
              Chúng tôi sẽ chỉnh sửa Dữ liệu cá nhân khi được Quý khách yêu cầu hoặc theo
              quy định pháp luật chuyên ngành. Trường hợp không thể thực hiện, chúng tôi
              sẽ thông báo tới Quý khách theo thỏa thuận giữa chúng tôi và Quý khách, quy
              định pháp luật.
            </p>
            <p>
              4. Quý khách được quyền xóa hoặc yêu cầu xóa Dữ liệu cá nhân
              của mình theo quy định của khoản 6 Điều 5 Văn Bản này.
            </p>
            <p>
              5. Quý khách được quyền yêu cầu hạn chế Xử lý Dữ liệu cá nhân
              của mình theo quy định pháp luật.
            </p>
            <p>
              Việc hạn chế xử lý dữ liệu sẽ được chúng tôi thực hiện sau khi có yêu cầu
              của Quý khách phù hợp với điều kiện kỹ thuật cho phép trừ trường hợp pháp
              luật có quy định khác hoặc theo thỏa thuận của các bên.
            </p>
            <p>
              6. Quý khách được quyền yêu cầu chúng tôi cung cấp cho bản
              thân Dữ liệu cá nhân của mình, trừ trường hợp pháp luật có quy định khác.
            </p>
            <p>
              Việc cung cấp Dữ liệu cá nhân của Quý khách sẽ được Mobilearn.vn thực hiện
              sau khi có yêu cầu của Quý khách, trừ trường hợp pháp luật có quy định
              khác.
            </p>
            <p>
              7. Quý khách được quyền yêu cầu rút lại sự đồng ý đối với các
              mục đích xử lý mà Quý khách đồng ý cho phép Mobilearn.vn xử lý, trừ trường
              hợp pháp luật có quy định khác. Khi nhận được yêu cầu, Mobilearn.vn thông
              báo cho Quý khách về hậu quả, thiệt hại có thể xảy ra khi rút lại sự đồng
              ý.
            </p>
            <p>
              Trường hợp việc rút lại sự đồng ý của Quý khách ảnh hưởng tới việc thực
              hiện hợp đồng giữa Mobilearn.vn và Quý khách, nghĩa vụ pháp lý của
              Mobilearn.vn, tính mạng, tài sản và quyền, lợi ích hợp pháp của Quý khách,
              tổ chức, cá nhân khác, nhiệm vụ bảo vệ an ninh quốc gia, trật tự an toàn xã
              hội, chúng tôi có quyền hạn chế, tạm ngừng, chấm dứt, hủy bỏ một phần hoặc
              toàn bộ hợp đồng cung cấp sản phẩm, hàng hóa, dịch vụ giữa Mobilearn.vn và
              Khách hàng. Chúng tôi không chịu bất kỳ trách nhiệm pháp lý hoặc bồi thường
              cho bất kỳ tổn thất nào phát sinh đối với Quý khách trong trường hợp này.
            </p>
            <p>
              8. Quý khách được quyền phản đối Mobilearn.vn Xử lý Dữ liệu cá
              nhân của mình nhằm ngăn chặn hoặc hạn chế tiết lộ Dữ liệu cá nhân hoặc lý
              do khác theo quy định pháp luật.
            </p>
            <p>
              Chúng tôi sẽ thực hiện yêu cầu phản đối của Quý khách sau khi nhận được yêu
              cầu, trừ trường hợp pháp luật có quy định khác.
            </p>
            <p>
              Trường hợp việc phản đối của Quý khách ảnh hưởng tới việc thực hiện hợp
              đồng giữa Mobilearn.vn và Quý khách, nghĩa vụ pháp lý của Mobilearn.vn,
              tính mạng, tài sản và quyền, lợi ích hợp pháp của Quý khách, tổ chức, cá
              nhân khác, nhiệm vụ bảo vệ an ninh quốc gia, trật tự an toàn xã hội, chúng
              tôi có quyền hạn chế, tạm ngừng, chấm dứt, hủy bỏ một phần hoặc toàn bộ hợp
              đồng cung cấp Sản phẩm, hàng hóa, dịch vụ giữa Mobilearn.vn và Khách hàng.
              Chúng tôi không chịu bất kỳ tổn thất nào phát sinh đối với Quý khách trong
              trường hợp này.
            </p>
            <p>
              9. Quý khách có quyền khiếu nại, tố cáo hoặc khởi kiện theo
              quy định pháp luật .
            </p>
            <p>
              10. Quý khách có quyền yêu cầu bồi thường đối với thiệt hại thực
              tế theo quy định pháp luật nếu Mobilearn.vn có hành vi vi phạm quy định về
              bảo vệ Dữ liệu cá nhân của mình, trừ trường hợp các bên có thỏa thuận khác
              hoặc pháp luật có quy định khác.
            </p>
            <p>
              11. Quý khách có quyền tự bảo vệ theo quy định pháp luật có liên
              quan, bao gồm nhưng không giới hạn Bộ luật Dân sự, hoặc yêu cầu cơ quan, tổ
              chức có thẩm quyền thực hiện các phương thức bảo vệ quyền dân sự, như buộc
              chấm dứt hành vi xâm phạm, buộc xin lỗi, cải chính công khai, buộc bồi
              thường thiệt hại…
            </p>
            <p>
              12. Các quyền khác theo Văn Bản này và theo quy định pháp luật.
            </p>
            <p>
              13. Quý khách có thể thực hiện các quyền tại khoản 1, 2, 3, 4, 5,
              6, 7, 8, 9 và 10 Điều này bằng cách truy cập vào Mạng xã hội
              <a href="https://viettel.vn/">
                https://mobilearn.vn.vn
              </a>
              hoặc gọi tới số đường dây nóng 1800.9230 hoặc gửi email theo địa chỉ
              colearn.telco@gmail.com hoặc phương thức khác theo quy định pháp luật và quy
              định của Mobilearn.vn tại từng thời điểm. Mobilearn.vn có thể xác minh các
              thông tin cần thiết từ Khách hàng để thực hiện các yêu cầu của Khách hàng.
            </p>
            <p>
              <strong>ĐIỀU 11.</strong> <strong>NGHĨA VỤ CỦA KHÁCH HÀNG</strong>
            </p>
            <p>
              1. Tuân thủ các quy định pháp luật, quy định của Mobilearn.vn
              liên quan đến Xử lý Dữ liệu cá nhân của Quý khách.
            </p>
            <p>
              2. Cung cấp đầy đủ, trung thực, chính xác Dữ liệu cá nhân, các
              thông tin khác theo yêu cầu của Mobilearn.vn khi tìm kiếm, tiếp cận, mua,
              đăng ký sử dụng và sử dụng Sản phẩm, hàng hóa, dịch vụ và khi có thay đổi
              về các thông tin này. Chúng tôi sẽ tiến hành bảo mật Dữ liệu cá nhân của
              Quý khách căn cứ trên thông tin Quý khách đã cung cấp. Do đó, nếu có bất kỳ
              thông tin sai lệch nào, chúng tôi sẽ không chịu trách nhiệm trong trường
              hợp thông tin đó làm ảnh hưởng hoặc hạn chế quyền lợi của Quý khách. Trường
              hợp không thông báo, nếu có phát sinh rủi ro, tổn thất thì Quý khách chịu
              trách nhiệm về những sai sót hay hành vi lợi dụng, lừa đảo khi sử dụng Sản
              phẩm, hàng hóa, dịch vụ do lỗi của Quý khách hoặc do không cung cấp đúng,
              đầy đủ, chính xác, kịp thời sự thay đổi thông tin; bao gồm cả thiệt hại về
              tài chính, chi phí phát sinh do thông tin cung cấp sai hoặc không thống
              nhất.
            </p>
            <p>
              3. Phối hợp với chúng tôi, cơ quan nhà nước có thẩm quyền hoặc
              bên thứ ba trong trường hợp phát sinh các vấn đề ảnh hưởng đến tính bảo mật
              Dữ liệu cá nhân của Quý khách.
            </p>
            <p>
              4. Tự bảo vệ Dữ liệu cá nhân của Quý khách; yêu cầu các tổ
              chức, cá nhân khác có liên quan bảo vệ Dữ liệu cá nhân của Quý khách; chủ
              động áp dụng các biện pháp nhằm bảo vệ Dữ liệu cá nhân của Quý khách trong
              quá trình sử dụng Sản phẩm, hàng hóa, dịch vụ của Mobilearn.vn; thông báo
              kịp thời cho chúng tôi khi phát hiện thấy có sai sót, nhầm lẫn về Dữ liệu
              cá nhân hoặc nghi ngờ Dữ liệu cá nhân đang bị xâm phạm.
            </p>
            <p>
              5. Tôn trọng, bảo vệ Dữ liệu cá nhân của người khác.
            </p>
            <p>
              6. Tự chịu trách nhiệm đối với những thông tin, dữ liệu mà Quý
              khách tạo lập, cung cấp trên không gian mạng; tự chịu trách nhiệm trong
              trường hợp Dữ liệu cá nhân bị rò rỉ, xâm phạm do lỗi của mình.
            </p>
            <p>
              7. Thường xuyên cập nhật các quy định, chính sách liên quan
              đến việc bảo vệ và Xử lý Dữ liệu cá nhân của Mobilearn.vn trong từng thời
              kỳ được thông báo tới Quý khách qua Kênh giao dịch của Mobilearn.vn.
            </p>
            <p>
              8. Thực hiện quy định pháp luật về bảo vệ dữ liệu cá nhân và
              tham gia phòng, chống các hành vi vi phạm quy định về bảo vệ dữ liệu cá
              nhân.
            </p>
            <p>
              9. Các trách nhiệm khác theo quy định pháp luật.
            </p>
            <p>
              <strong>ĐIỀU 12.</strong> <strong>ĐIỀU KHOẢN CHUNG</strong>
            </p>
            <p>
              1. Văn Bản này có hiệu lực kể từ ngày ký, đồng thời là Thông
              báo xử lý Dữ liệu cá nhân theo quy định pháp luật hiện hành. Khách hàng cam
              kết đã đọc, hiểu rõ và đồng ý với toàn bộ nội dung của Văn Bản này.
            </p>
            <p>
              2. Văn Bản này được lập ra chỉ nhằm mục đích duy nhất là điều
              chỉnh hoạt động Xử lý dữ liệu cá nhân tuân thủ theo các quy định pháp luật.
              Nói cách khác, Văn Bản này có hiệu lực pháp lý độc lập, không phải và không
              phụ thuộc vào hợp đồng cung cấp Sản phẩm, hàng hóa, dịch vụ giữa
              Mobilearn.vn với Khách hàng.
            </p>
            <p>
              3. Khách hàng hiểu và đồng ý rằng, Văn Bản này có thể được sửa
              đổi theo từng thời kỳ và được thông báo tới Khách hàng thông qua các Kênh
              giao dịch của Mobilearn.vn trước khi áp dụng. Những thay đổi và thời điểm
              có hiệu lực sẽ được cập nhật tại các Kênh giao dịch và/hoặc thông qua các
              phương thức khác của Mobilearn.vn. Trong trường hợp pháp luật có yêu cầu,
              Mobilearn.vn sẽ thu thập sự đồng ý của Khách hàng đối với những thay đổi
              đó.
            </p>
            <p>
              4. Khách hàng đã đọc, hiểu, đồng ý và cam kết thực hiện nghiêm
              túc các điều khoản, điều kiện được nêu tại Văn Bản này. Các vấn đề chưa
              được quy định được thực hiện theo quy định pháp luật, hướng dẫn của cơ quan
              Nhà nước có thẩm quyền và/hoặc các sửa đổi, bổ sung Văn Bản này trong từng
              thời kỳ.
            </p>
            <p>
              5. Nếu phát sinh tranh chấp, các bên sẽ chủ động thương lượng
              trên tinh thần thiện chí, hợp tác. Trường hợp không thương lượng được, các
              bên có quyền yêu cầu cơ quan có thẩm quyền giải quyết theo quy định pháp
              luật.
            </p>
          </div>
        </template>
        <template v-if="tabActive === 3">
          <h4 class="font-weight-bold text-center">THỎA THUẬN CUNG CẤP VÀ SỬ DỤNG DỊCH VỤ MẠNG XÃ HỘI</h4>
          <div class="text-justify text-break">
            <p>
              <strong>Điều 1. Điều khoản định nghĩa</strong>
            </p>
            <p>
              1. Nhà cung cấp dịch  vụ mạng xã hội (sau đây gọi là “Nhà cung cấp”) là Công
              ty Cổ Phần DataNova Việt  Nam, được thành lập và hoạt động hợp pháp theo quy
              định của pháp luật Việt Nam.
            </p>
            <p>
              2. Hệ thống mạng xã  hội là nền tảng kỹ thuật và cơ sở hạ tầng để cung cấp
              dịch vụ cho Người sử dụng  dịch vụ mạng xã hội trên trang mạng xã hội của
              Công ty Cổ Phần DataNova Việt  Nam.
            </p>
            <p>
              3. Người sử dụng dịch  vụ mạng xã hội (sau đây gọi là “Người sử dụng”) là cá
              nhân sở hữu tài khoản  mạng xã hội hợp pháp để sử dụng các dịch vụ mạng xã
              hội trên website của Nhà  cung cấp. Quy định rằng, dựa vào sự xem xét, cân
              nhắc của Bộ phận kiểm duyệt  nội dung mà bài viết của Người sử dụng được
              hoặc không được phép đăng tải trên  trang mạng xã hội của Nhà cung cấp, trên
              cơ sở đánh giá nội dung của bài viết  phải phù hợp với phạm vi thông tin
              trao đổi của Nhà cung cấp trên trang mạng xã  hội ; đồng thời phải tuân theo
              quy định tại Thỏa thuận này và pháp luật.
            </p>
            <p>
              4. Thông tin cá nhân  là thông tin gắn với việc xác định danh tính của Người
              sử dụng, do Người sử  dụng đồng ý cung cấp để tạo tài khoản đăng nhập hệ
              thống mạng xã hội của Nhà  cung cấp theo mẫu đăng ký trên trang mạng xã hội.
            </p>
            <p>
              5. Thông tin riêng là  thông tin mà Người sử dụng cung cấp trên mạng xã hội
              dưới hình thức không công  khai hoặc chỉ công khai cho  một hoặc một nhóm
              Người sử dụng đã được xác  định thông tin cá nhân cụ thể.
            </p>
            <p>
              6. Thông tin công cộng  là thông tin trên mạng xã hội do Nhà cung cấp hoặc
              Người sử dụng công khai cho  tất cả những người sử dụng khác được biết mà
              không cần xác định thông tin cá  nhân cụ thể của những Người sử dụng đó.
            </p>
            <p>
              7. Ban quản trị Mạng  xã hội là bộ phận trực thuộc Nhà cung cấp, chịu trách
              nhiệm quản lý hoạt động  của trang mạng xã hội; giải quyết các trường hợp vi
              phạm, khiếu nại của Người  sử dụng và các vấn đề khác liên quan trong quá
              trình quản lý hoạt động trang  mạng xã hội.
            </p>
            <p>
              8. Bộ phận kiểm duyệt  nội dung là bộ phận trực thuộc Nhà cung cấp, chịu
              trách nhiệm kiểm tra, phê  duyệt nội dung bài viết, thông tin do Người sử
              dụng cung cấp để đăng tải lên  mạng xã hội ; đồng thời hỗ trợ Ban quản trị
              Mạng xã hội phát hiện và xử lý vi  phạm trong quá trình hoạt động trang mạng
              xã hội.
            </p>
            <p>
              Điều 2. Phạm vi nội  dung trao đổi, chia sẻ, cung cấp trên mạng xã hội
            </p>
            <p>
              Người sử dụng được tự  do trao đổi, chia sẻ, cung cấp thông tin thuộc lĩnh
              vực giáo dục, khoa học kỹ  thuật phục vụ cho hoặc liên quan đến giáo dục,
              học tập, trừ các nội dung cấm  trao đổi, chia sẻ trên mạng xã hội như sau:
            </p>
            <p>
              1. Nội dung chống lại  Nhà nước Cộng hòa Xã hội Chủ nghĩa Việt Nam, gây
              phương hại đến an ninh quốc  gia, trật tự an toàn xã hội; phá hoại khối đại
              đoàn kết dân tộc; tuyên truyền  chiến tranh, khủng bố; gây hận thù, mâu
              thuẫn giữa các dân tộc, sắc tộc, tôn  giáo.
            </p>
            <p>
              2. Nội dung tuyên  truyền, kích động bạo lực, dâm ô, đồi trụy, tội ác, tệ
              nạn xã hội, mê tín dị  đoan, phá hoại thuần phong mỹ tục của dân tộc.
            </p>
            <p>
              3. Nội dung liên quan  đến bí mật nhà nước, bí mật quân sự, an ninh, kinh
              tế, đối ngoại và những bí  mật khác do pháp luật quy định.
            </p>
            <p>
              4. Thông tin xuyên tạc,  vu khống, xúc phạm uy tín của tổ chức, danh dự và
              nhân phẩm của cá nhân.
            </p>
            <p>
              5. Nội dung quảng cáo,  tuyên truyền, mua bán hàng hóa, dịch vụ bị cấm,
              truyền bá tác phẩm báo chí, văn  học, nghệ thuật, xuất bản phẩm bị cấm.
            </p>
            <p>
              6. Thông tin giả mạo,  thông tin sai sự thật xâm hại đến quyền và lợi ích
              hợp pháp của tổ chức, cá  nhân.
            </p>
            <p>
              7. Thông tin bất hợp  pháp, lừa gạt, bôi nhọ, sỉ nhục, khiêu dâm, xúc phạm,
              đe dọa, lăng mạ, thù hận,  kích động... hoặc trái với  chuẩn mực đạo đức
              chung của xã hội.
            </p>
            <p>
              8. Nội dung miêu tả tỉ  mỉ những hành động dâm ô, bạo lực, giết người rùng
              rợn; các hình ảnh phản cảm,  thiếu tính nhân văn, không  phù hợp với thuần
              phong, mỹ tục Việt Nam.
            </p>
            <p>
              9. Nội dung tuyên  truyền những thông điệp mang tính quảng cáo, mời gọi,
              quảng bá cơ hội đầu tư  hay bất kỳ dạng liên lạc nào không thuộc phạm vi
              thông tin trao đổi, chia sẻ  trên mạng xã hội để phục vụ cho lợi ích cá nhân
              hoặc tổ chức nào khác.
            </p>
            <p>
              10. Thông tin có chứa  các loại virus hay các thành phần gây nguy hại đến hệ
              thống mạng xã hội, máy  tính, mạng internet và các  thông tin bảo mật của
              Nhà cung cấp và/hoặc của  Người sử dụng khác trên mạng xã hội.
            </p>
            <p>
              11. Thông tin xâm phạm  quyền tác giả theo quy định của Luật Sở hữu trí tuệ
              hiện hành.
            </p>
            <p>
              <strong>
                Điều 3. Quyền và nghĩa vụ của Người sử dụng dịch vụ mạng xã hội
              </strong>
            </p>
            <p>
              1. Quyền của Người sử  dụng dịch vụ mạng xã hội:
            </p>
            <p>
              a) Người sử dụng có  quyền được hướng dẫn sử dụng các công cụ, tính năng
              phục vụ cho việc xây dựng  nội dung thông tin, tiến hành chia sẻ thông tin
              và sử dụng các dịch vụ tiện ích  trên mạng xã hội.
            </p>
            <p>
              Người sử dụng có quyền  đóng góp ý kiến cho Nhà cung cấp trong quá trình sử
              dụng các dịch vụ mạng xã  hội. Các kiến nghị được gửi trực tiếp bằng thư
              hoặc email đến Ban quản trị Mạng  xã hội (thông tin của Ban quản trị Mạng xã
              hội được đề cập tại điểm d khoản 1  Điều 7 Thỏa thuận này).
            </p>
            <p>
              c) Người sử dụng có  quyền được bảo vệ bí mật thông tin cá nhân, thông tin
              riêng và các thông tin có  liên quan khác theo quy định tại Thỏa thuận này
              và quy định của pháp luật.
            </p>
            <p>
              d) Người sử dụng có  quyền đăng tải bài viết, bình luận trên mạng xã hội
              thông qua sự kiểm duyệt của  Bộ phận kiểm duyệt nội dung, nhằm đảm bảo nội
              dung thông tin do Người sử dụng  cung cấp thuộc phạm vi thông tin được phép
              trao đổi theo quy định tại Điều 2  Thỏa thuận này và quy định của pháp luật.
            </p>
            <p>
              <strong>2. Nghĩa vụ của người sử dụng dịch vụ mạng xã hội:</strong>
            </p>
            <p>
              a) Trước khi sử dụng  dịch vụ mạng xã hội, bao gồm nhưng không giới hạn việc
              sử dụng các dịch vụ hay  công cụ trên website của Nhà cung cấp, Người sử
              dụng phải đăng ký tài khoản  mạng xã hội và chịu trách nhiệm cho mọi hoạt
              động được thực hiện dưới tên tài  khoản và mật khẩu đã đăng ký.
            </p>
            <p>
              b) Khi đăng ký tài khoản mạng xã hội, Người sử dụng phải từ đủ 16  (mười
              sáu) tuổi trở lên. Trường hợp Người sử dụng dưới 16 (mười sáu) tuổi và  chưa
              có chứng minh nhân dân hoặc thẻ căn cước công dân hoặc hộ chiếu, khi đăng
              ký tài khoản mạng xã hội, Người sử dụng phải đảm bảo có sự đồng ý của người
              giám hộ hợp pháp và người giám hộ hợp pháp phải chịu trách nhiệm trước <br/>
              pháp luật về việc đăng ký tài khoản mạng xã hội của Người sử dụng. Nếu không
              có  sự đồng ý của người giám hộ hợp pháp, Nhà cung cấp có quyền không cho
              phép  Người sử dụng đăng ký tài khoản mạng xã hội.
            </p>
            <p>
              c) Người sử dụng phải  đảm bảo các thông tin cá nhân đang sử dụng và đã cung
              cấp cho Nhà cung cấp là  mới nhất, trung thực, đầy đủ, chính xác theo hướng
              dẫn trong mẫu đơn đăng ký sử  dụng dịch vụ mạng xã hội của Nhà cung cấp.
              Đồng thời Người sử dụng phải luôn  duy trì và cập nhật kịp thời dữ liệu đăng
              ký để đảm bảo rằng dữ liệu này là mới  nhất, đầy đủ, trung thực và chính
              xác.
            </p>
            <p>
              d) Người sử dụng có  trách nhiệm tự bảo vệ mật khẩu của mình trên mạng xã
              hội. Nhà cung cấp khuyến  khích Người sử dụng nên dùng mật khẩu mang tính
              bảo mật cao (bao gồm chữ  thường, chữ hoa, chữ số và ký hiệu cho phép, đồng
              thời đảm bảo độ dài của mật  khẩu theo quy định của nhà cung cấp nhằm đảm
              bảo tối ưu tính an toàn).
            </p>
            <p>
              Người sử dụng tự chịu  trách nhiệm về các nội dung, hình ảnh của các bình
              luận, bài viết được đăng tải  trên mạng xã hội, cũng như toàn bộ quá trình
              giao dịch với các đối tác trên  mạng xã hội trong quá trình sử dụng dịch vụ
              mạng xã hội.
            </p>
            <p>
              f) Người sử dụng đồng  ý không tái bản, sao chụp, bán, bán lại hoặc lợi dụng
              vì các mục đích thương  mại bất kỳ dịch vụ nào, nội dung nào (trong bài
              viết, bình luận) của mạng xã  hội (bao gồm cả tài khoản cá nhân của Người sử
              dụng) hoặc nhân danh bất kỳ đối  tác thứ ba nào nếu chưa được Nhà cung cấp
              cho phép bằng văn bản.
            </p>
            <p>
              g) Người sử dụng phải  thông báo cho Ban quản trị Mạng xã hội biết khi tài
              khoản và mật khẩu bị truy  cập trái phép. Nhà cung cấp không chịu bất kỳ
              trách nhiệm nào, dù trực tiếp hay  gián tiếp, đối với những thiệt hại mất
              mát xảy ra do Người sử dụng không thông  báo theo quy định tại thỏa thuận
              này.
            </p>
            <p>
              h) Người sử dụng có  trách nhiệm hợp tác và cung cấp thông tin phù hợp khi
              có yêu cầu của cơ quan  nhà nước có thẩm quyền để phục vụ hoạt động điều
              tra, hoặc các công tác thống  kê, hoặc các công việc phù hợp khác theo quy
              định của pháp luật.
            </p>
            <p>
              i) Người sử dụng phải  tuân thủ quy định của pháp luật về chia sẻ, trao đổi
              thông tin, bảo vệ quyền sở  hữu trí tuệ, bảo vệ quyền lợi người tiêu dùng và
              các quy định có liên quan khác  của pháp luật khi sử dụng các dịch vụ trên
              mạng xã hội.
            </p>
            <p>
              j) Người sử dụng cam  kết không sử dụng dịch vụ mạng xã hội vào các mục đích
              thương mại, bất hợp  pháp, lừa đảo, đe dọa, phá hoại; tạo ra và phát tán
              virus gây hư hại tới hệ  thống, cấu hình mạng xã hội; truyền tải thông tin
              của mạng xã hội hay sử dụng  dịch vụ mạng xã hội vào mục đích đầu cơ, lũng
              đoạn, chia rẽ thị trường. Trong  trường hợp vi phạm, Người sử dụng phải chịu
              trách nhiệm về các hành vi của mình  trước pháp luật.
            </p>
            <p>
              k) Người sử dụng cam  kết không được thay đổi, chỉnh sửa, sao chép, truyền
              bá, phân phối, cung cấp và  tạo ra những công cụ tương tự của các nội dung,
              dịch vụ trên mạng xã hội cung  cấp cho bên thứ ba nếu không được sự đồng ý
              của Nhà cung cấp trong từng trường  hợp cụ thể.
            </p>
            <p>
              l) Người sử dụng không  được hành động gây mất uy tín của Nhà cung cấp dưới
              mọi hình thức như đăng tải  những thông tin (bao gồm dưới hình thức bình
              luận và đăng bài viết) mang tính  so sánh, chê bai các đối thủ cạnh tranh
              trực tiếp với Nhà cung cấp hay những  nội dung xúc phạm đến danh dự, nhân
              phẩm của bất kỳ cá nhân, tổ chức nào khác  làm ảnh hưởng đến hoạt động kinh
              doanh của Nhà cung cấp.
            </p>
            <p>
              m) Người sử dụng không  được tự ý khóa mật mã, sử dụng trái phép mật khẩu và
              thông tin riêng của Người  sử dụng khác trên mạng xã hội.
            </p>
            <p>
              Người sử dụng không  được xâm phạm, tiếp cận hay sử dụng bất kỳ phần nào
              trong máy chủ của mạng xã  hội. Nghiêm cấm mọi hành vi lợi dụng lỗi hệ thống
              để trục lợi cá nhân gây thiệt  hại đến Nhà cung cấp.
            </p>
            <p>
              <strong>
                Điều 4. Quyền và nghĩa vụ của Nhà cung cấp dịch vụ mạng xã hội
              </strong>
            </p>
            <p>
              1. Quyền của Nhà cung  cấp dịch vụ mạng xã hội:
            </p>
            <p>
              a) Đối với hệ thống  mạng xã hội:
            </p>
            <p>
              i. Nhà cung cấp có  toàn quyền bổ sung, sửa đổi hay xóa bỏ bất kỳ giao diện,
              sự trình bày, thành  phần hoặc chức năng, nội dung của trang mạng xã hội tại
              bất kỳ thời điểm nào mà  không cần báo trước.
            </p>
            <p>
              ii. Nhà cung cấp có  toàn quyền bổ sung, thay đổi hay xóa bỏ bất kỳ nội dung
              nào trong thỏa thuận  này mà không cần báo trước. Với việc tiếp tục sử dụng
              dịch vụ trên mạng xã hội  sau những thay đổi, bổ sung đó, Người sử dụng mặc
              nhiên đồng ý chấp hành các  nội dung đã được sửa đổi, bổ sung của Thỏa thuận
              này.
            </p>
            <p>
              iii. Nhà cung cấp có  quyền không bảo đảm, hoặc cam kết rằng mỗi phần nội
              dung của mạng xã hội sẽ  không bị gián đoạn, không có lỗi, không có virus,
              đúng giờ, an toàn, chính xác,  ổn định hay bất kỳ nội dung nào là an toàn
              cho Người sử dụng.
            </p>
            <p>
              iv. Nhà cung cấp có  quyền tiến hành xây dựng các chính sách dịch vụ áp dụng
              cho từng đối tượng phù  hợp trên mạng xã hội. Các chính sách này sẽ được
              công bố trên mạng xã hội tại từng  thời điểm theo quyết định của Ban quản
              trị Mạng xã hội.
            </p>
            <p>
              b) Đối với Người sử  dụng dịch vụ mạng xã hội:
            </p>
            <p>
              i. Nhà cung cấp có  quyền yêu cầu Người sử dụng cung cấp các thông tin cá
              nhân cần thiết để  được sử dụng dịch vụ mạng xã hội, và hưởng phí dịch vụ áp
              dụng trên mạng xã hội  tùy thuộc vào quy định và chính sách của Nhà cung cấp
              tại từng thời điểm, căn  cứ theo các quy định liên quan của pháp luật Việt
              Nam.
            </p>
            <p>
              ii. Nhà cung cấp có  quyền tiết lộ bất kỳ nội dung nào mà Người sử dụng đăng
              tải lên mạng xã hội,  bao gồm cả thông tin cá nhân của <br/>
              Người sử dụng nếu Người sử dụng đồng ý hoặc pháp luật, cơ quan nhà nước có
              thẩm  quyền yêu cầu cung cấp hoặc nhằm phục vụ mục đích nghiên cứu, cải
              thiện chất  lượng dịch vụ mạng xã hội của Nhà cung cấp.
            </p>
            <p>
              iii. Nhà cung cấp có  toàn quyền xóa bỏ bình luận, bài viết của Người sử
              dụng hoặc từ chối việc truy  cập của Người sử dụng vào trang mạng xã hội
              hoặc bất kỳ phần nào của mạng xã  hội ngay lập tức mà không  cần báo trước
              nếu Nhà cung cấp phát hiện Người  sử dụng đã vi phạm bất cứ quy định nào
              trong thỏa thuận này.
            </p>
            <p>
              iv. Nhà cung cấp có  quyền không xác thực bất cứ một vấn đề, biến cố hoặc sự
              kiện nào và với bất kỳ  mục đích nào, tương ứng với bất kỳ người, sản phẩm
              hoặc dịch vụ nào của một bên  thứ ba.
            </p>
            <p>
              v. Nhà cung cấp có  toàn quyền từ chối xuất bản, loại bỏ hoặc ngăn chặn truy
              cập tới bất kỳ nội  dung nào mà người sử dụng đã cung cấp trên mạng xã hội
              vào bất cứ lúc nào, với  bất cứ lý do nào, có hoặc không có thông báo.
            </p>
            <p>
              vi. Nhà cung cấp có  quyền kiểm tra, xử lý các thông tin đăng tải của Người
              sử dụng cho phù hợp với  thuần phong mỹ tục, các quy tắc đạo đức, quy tắc
              đảm bảo an ninh quốc gia và  các quy định tại Thỏa thuận này và pháp luật.
              Theo đó, Nhà cung cấp có toàn  quyền cho phép hoặc không cho phép bài viết,
              bình luận của Người sử dụng xuất hiện  trên mạng xã hội.
            </p>
            <p>
              vii. Nếu Người sử dụng  không tuân thủ các quy định của Nhà cung cấp thì Nhà
              cung cấp có quyền khóa  quyền bình luận hoặc quyền đăng bài viết của Người
              sử dụng và/hoặc từ chối,  ngừng cung cấp dịch vụ mạng xã hội tùy theo sự xem
              xét của Nhà cung cấp và mức  độ vi phạm của hành vi.
            </p>
            <p>
              <strong>2. Nghĩa vụ của Nhà cung cấp dịch vụ mạng xã hội</strong>
            </p>
            <p>
              a) Nhà cung cấp phải  cung cấp dịch vụ mạng xã hội theo đúng những nội dung,
              mục tiêu đã để ra trong  thỏa thuận này.
            </p>
            <p>
              b) Nhà cung cấp phải  thiết lập và duy trì các biện pháp bảo vệ bí mật thông
              tin cá nhân, thông tin  riêng của Người sử dụng; thông báo cho Người sử dụng
              về quyền, trách nhiệm và  các rủi ro khi lưu trữ, trao đổi và chia sẻ thông
              tin trên mạng xã hội.
            </p>
            <p>
              c) Nhà cung cấp phải  bảo đảm quyền quyết định của người sử dụng khi cho
              phép thông tin cá nhân,  thông tin riêng của mình được cung cấp cho tổ chức,
              cá nhân khác.
            </p>
            <p>
              d) Nhà cung cấp không  được chủ động cung cấp thông tin công cộng có các nội
              dung vi phạm quy định tại  Điều 2 Thỏa thuận này.
            </p>
            <p>
              e) Nhà cung cấp phải  phối hợp với cơ quan quản lý nhà nước có thẩm quyền để
              loại bỏ hoặc ngăn chặn  thông tin có nội dung vi phạm quy định tại Điều 2
              Thỏa thuận này khi có yêu  cầu.
            </p>
            <p>
              f) Nhà cung cấp phải  cung cấp thông tin cá nhân và thông tin khác có liên
              quan của Người sử dụng liên  quan đến hoạt động khủng bố, tội phạm, vi phạm
              pháp luật khi có yêu cầu của cơ  quan quản lý nhà nước có thẩm quyền.
            </p>
            <p>
              Nhà cung cấp phải cam kết đảm bảo ít nhất 01 hệ thống máy chủ  đặt tại Việt
              Nam đáp ứng việc thanh tra, kiểm tra, lưu trữ, cung cấp thông tin theo  yêu
              cầu của cơ quan quản lý nhà nước có thẩm quyền và giải quyết khiếu nại của
              Người sử dụng đối với việc cung cấp dịch vụ theo quy định của Bộ Thông tin
              và  Truyền thông.
            </p>
            <p>
              h) Nhà cung cấp chịu trách nhiệm báo cáo theo quy định và chịu  sự thanh
              tra, kiểm tra của các cơ quan quản lý nhà nước có thẩm quyền.
            </p>
            <p>
              <strong>
                Điều 5. Cơ chế xử lý đối với Người sử dụng vi phạm Thỏa thuận  cung cấp
                và sử dụng mạng xã hội
              </strong>
            </p>
            <p>
              1. Nguyên tắc xử lý vi  phạm :
            </p>
            <p>
              Khi Ban quản trị Mạng  xã hội phát hiện Người sử dụng thực hiện hành vi vi
              phạm quy định tại Thỏa  thuận này, quy định rằng, dựa theo sự xem xét, cân
              nhắc của Ban quản trị Mạng  xã hội về mức độ thiệt hại do hành vi vi phạm
              của Người sử dụng gây ra đối với  Hệ thống mạng xã hội và quyền lợi của
              Người sử dụng khác hay các đối tượng có  liên quan khác theo quy định tại
              Thỏa thuận này, Ban quản trị Mạng xã hội sẽ  quyết định hình thức xử lý vi
              phạm tương ứng.
            </p>
            <p>
              2. Quy trình xử lý vi  phạm:
            </p>
            <p>
              Bước 1: Ban quản trị  Mạng xã hội gửi thông báo nhắc nhở Người sử dụng về
              hành vi vi phạm bằng tin  nhắn hoặc thư điện tử hoặc thư thông thường hoặc
              thông báo trên mạng xã hội  (Tối đa mỗi lỗi vi phạm chỉ được nhắc nhở 01
              (một) lần); nội dung nhắc nhở đã  bao gồm yêu cầu chấm dứt hành vi vi phạm
              hoặc yêu cầu đính chính, sửa đổi thông  tin sai phạm cho phù hợp với quy
              định tại Thỏa thuận này.
            </p>
            <p>
              Bước 2: Trường hợp  Người sử dụng tái phạm, tùy theo mức độ vi phạm của hành
              vi mà Ban quản trị  Mạng xã hội sẽ quyết định khóa quyền bình luận, quyền
              đăng bài viết hoặc xóa  tài khoản mạng xã hội của Người sử dụng vĩnh viễn mà
              không thông báo trước.
            </p>
            <p>
              <em>
                Lưu ý : Trường hợp Người sử dụng thực hiện hành vi vi phạm pháp  luật,
                vi phạm quy định tại Thỏa thuận làm ảnh hưởng nghiêm trọng đến sự vận
                hành hệ thống mạng xã hội cũng như quyền lợi của các đối tượng khác có
                liên  quan, Ban quản trị Mạng xã hội sẽ quyết định xóa và chặn tài khoản
                mạng xã hội  của Người sử dụng ngay lập tức kể từ lần đầu phát hiện vi
                phạm; đồng thời đưa  vụ việc ra cơ quan quản lý nhà nước để xử lý theo
                quy định của pháp luật nước  Cộng hòa xã hội chủ nghĩa Việt Nam.
              </em>
            </p>
            <p>
              <strong>
                Điều 6. Cảnh báo về các rủi ro khi lưu trữ, trao đổi và chia sẻ  thông
                tin trên mạng xã hội
              </strong>
            </p>
            <p>
              1. Nhà cung cấp cho  phép Người sử dụng đăng tải, lưu trữ, gửi hoặc nhận nội
              dung (từ bài viết hoặc  bình luận), bao gồm các hình ảnh, clip trên mạng xã
              hội. Tuy nhiên, Nhà cung  cấp không đảm bảo những thông tin do Người sử dụng
              tải lên, lưu trữ, chia sẻ  trên mạng xã hội là chính xác, an toàn và không
              chứa đựng các rủi ro về an toàn  thông tin.
            </p>
            <p>
              2. Trên website của  mạng xã hội có thể xuất hiện link website, hoặc biểu
              tượng website khác, những  website này có thể không thuộc kiểm soát hoặc sở
              hữu của Nhà cung cấp. Việc  truy cập tới các trang khác này hoàn toàn có thể
              gặp rủi ro, nguy hiểm. Người  sử dụng hoàn toàn chịu trách nhiệm rủi ro khi
              sử dụng website liên kết này. Nhà  cung cấp sẽ không chịu trách nhiệm về nội
              dung của bất kỳ website hoặc điểm đến  nào mà Người sử dụng đã truy cập, sử
              dụng đường link, liên kết hiện trên mạng  xã hội.
            </p>
            <p>
              3. Người sử dụng phải  đảm bảo các nội dung được đăng tải lên mạng xã hội
              đều không vi phạm luật sở  hữu trí tuệ của Việt Nam và quốc tế. Sử dụng dịch
              vụ của mạng xã hội không có  nghĩa là Người sử dụng có bản quyền sử dụng
              những nội dung mà người sử dụng  truy cập được. Người sử dụng không được sử
              dụng những nội dung không phải của  mình trên mạng xã hội nếu không xin phép
              chủ sở hữu hợp pháp hoặc thực hiện các  thủ tục khác theo luật định.
            </p>
            <p>
              <strong>Điều 7. Phương thức giải quyết khiếu nại, tranh chấp</strong>
            </p>
            <p>
              1. Nguyên tắc về việc quyết tranh khiếu nại, tranh chấp
            </p>
            <p>
              a) Bất kỳ tranh chấp phát sinh trong quá trình sử dụng dịch vụ mạng xã hội của Người sử dụng sẽ được giải quyết theo các điều kiện tại Thỏa thuận này và các quy định của pháp luật hiện hành của nước Cộng hòa xã hội chủ nghĩa Việt Nam.
            </p>
            <p>
              b) Nhà cung cấp chỉ hỗ trợ, giải quyết khiếu nại của Người sử dụng trong trường hợp người sử dụng đã ghi đầy đủ, trung thực và chính xác thông tin khi đăng ký tài khoản.
            </p>
            <p>
              c) Mọi tranh chấp trên mạng xã hội sẽ được giải quyết theo tinh thần thương lượng, hòa giải bằng cách thu xếp để các bên gặp gỡ và trao đổi trực tiếp, hoặc theo bất kỳ biện pháp hòa giải nào khác được các bên lựa chọn thông qua chấp thuận bằng văn bản.
            </p>
            <p>
              d) Bất kỳ khiếu nại nào phát sinh trong quá trình sử dụng dịch vụ trên mạng xã hội đều sẽ được gửi đến Ban quản trị Mạng xã hội theo thông tin sau:
            </p>
            <p>
              <strong><em>Hotline :</em></strong>
              <a href="tel:18009230">
                <strong><em>1800.9230</em></strong>
              </a>
            </p>
            <p>
              <strong><em>Email : colearn.telco@gmail.com</em></strong>
            </p>
            <p>
              Ngay sau khi xảy ra sự kiện phát sinh khiếu nại, Ban quản trị Mạng xã hội sẽ căn cứ từng trường hợp cụ thể để có phương án giải quyết phù hợp.
            </p>
            <p>
              e) Trong thời hạn 24 giờ kể từ thời điểm nhận được khiếu nại, Ban quản trị Mạng sẽ trả lời cho Người khiếu nại về kết quả giải quyết khiếu nại, đồng thời hỗ trợ các bên thực hiện giải quyết tranh chấp cho từng trường hợp cụ thể;
            </p>
            <p>
              f) Thông tin khiếu nại, tranh chấp được bảo mật tuyệt đối theo quy định về bảo vệ dữ liệu cá nhân. Nhà cung cấp cam kết xử lý trung thực, khách quan, đúng quy định pháp luật và bảo đảm quyền lợi của các bên liên quan trong suốt quá trình giải quyết khiếu nại.
            </p>
            <p>
              g) Trường hợp biện pháp thương lượng, hòa giải không đạt được kết quả như mong muốn, một trong các bên được tự do lựa chọn việc đưa vụ việc ra Tòa án nhân dân có thẩm quyền của Việt Nam để giải quyết theo quy định của pháp luật Việt Nam.
            </p>
            <p>
              2.Cơ chế giải quyết khiếu nại, tranh chấp giữa người sử dụng dịch vụ mạng xã hội với cơ quan, tổ chức, doanh nghiệp cung cấp dịch vụ mạng xã hội hoặc với tổ chức, cá nhân khác
              Quy trình xem xét, giải quyết khiếu nại, tranh chấp:
            </p>
            <p>
              a)	Ban quản trị Mạng sẽ tiếp nhận khiếu nại từ Người sử dụng;
            </p>
            <p>
              b)	Ban quản trị Mạng xã hội chuyển thông tin khiếu nại cho phòng ban chịu trách nhiệm giải quyết;
            </p>
            <p>
              c)	Trường hợp phát hiện vi phạm từ phía tổ chức/cá nhân khác trên hệ thống, Ban quản trị Mạng có quyền áp dụng các biện pháp xử lý phù hợp theo quy định (như cảnh báo, tạm khóa tài khoản, gỡ bỏ nội dung vi phạm…);
            </p>
            <p>
              d)	Trường hợp lỗi hoặc sai sót thuộc về hệ thống dịch vụ, Nhà cung cấp sẽ chủ động khắc phục, đồng thời thông báo rõ ràng kết quả xử lý đến Người sử dụng;
            </p>
            <p>
              e)	Các khiếu nại, tranh chấp không thể giải quyết được thông qua thương lượng, hòa giải sẽ được tiến hành tại Tòa án có thẩm quyền theo thủ tục tố tụng quy định bởi pháp luật Việt Nam.
            </p>
            <p>
              e)	Các khiếu nại, tranh chấp không thể giải quyết được thông qua thương lượng, hòa giải sẽ được tiến hành tại Tòa án có thẩm quyền theo thủ tục tố tụng quy định bởi pháp luật Việt Nam.
            </p>
            <p>3.	Cơ chế xử lý đối với khiếu nại của người sử dụng dịch vụ với thông tin ảnh hưởng đến quyền và lợi ích hợp pháp của tổ chức, cá nhân do người sử dụng dịch vụ đăng tải trên mạng xã hội<br/>
            Khi có tổ chức, cá nhân cho rằng nội dung do người dùng đăng tải trên mạng xã hội xâm phạm quyền và lợi ích hợp pháp của tổ chức, cá nhân đó có quyền gửi yêu cầu khiếu nại đến Ban quản trị Mạng được xem xét và xử lý.
            Khiếu nại cần được cung cấp đầy đủ và chính xác các thông tin: Nội dung bị cho là vi phạm, Bằng chứng chứng minh quyền, lợi ích bị ảnh hưởng; và gửi tới Ban quản trị Mạng.</p>

            <p>Quy trình xem xét, giải quyết khiếu nại, tranh chấp:</p>
            <p>a)	Ban quản trị Mạng sẽ tiếp nhận khiếu nại từ Người sử dụng;</p>
            <p>b)	Ban quản trị Mạng xã hội chuyển thông tin khiếu nại cho phòng ban chịu trách nhiệm giải quyết;</p>
            <p>c)	Nếu xác minh cho thấy nội dung vi phạm pháp luật hoặc quy định của Nhà cung cấp, hệ thống có quyền:<br/>
            Gỡ bỏ vĩnh viễn nội dung;<br/>
            Cảnh cáo, hạn chế quyền truy cập hoặc khóa tài khoản của người đăng tải;<br/>
            Thông báo kết quả xử lý đến bên khiếu nại;</p>
            <p>d)	Các khiếu nại, tranh chấp không thể giải quyết được thông qua thương lượng, hòa giải sẽ được tiến hành tại Tòa án có thẩm quyền theo thủ tục tố tụng quy định bởi pháp luật Việt Nam.</p>
            <p>
              <strong>
                Điều 8. Chính sách thu thập, xử lý các thông tin cá nhân của  Người sử
                dụng dịch vụ mạng xã hội
              </strong>
            </p>
            <p>
              1. Nhà cung cấp đảm  bảo rằng mọi thông tin cá nhân thu thập được trên
              website mạng xã hội là dựa  trên cơ sở đăng nhập và khai báo của Người sử
              dụng.
            </p>
            <p>
              2. Thông tin được thu  thập trên mạng xã hội sẽ được sử dụng rộng khắp trên
              toàn bộ dịch vụ mạng xã  hội mà Nhà cung cấp đang cung cấp cho Người sử
              dụng, đồng thời sẽ được sử dụng  cho tất cả các mục đích trên mạng xã hội,
              để đảm bảo tối ưu hoá tính năng và  hiệu quả của thông tin được sử dụng.
            </p>
            <p>
              3. Nhà cung cấp sẽ lưu  trữ các thông tin cá nhân do Người sử dụng đăng tải
              trên các hệ thống nội bộ  của Nhà cung cấp trong quá trình cung cấp dịch vụ
              cho Người sử dụng hoặc cho  đến khi hoàn thành mục đích thu thập.
            </p>
            <p>
              4. Nhà cung cấp không  tiết lộ, chia sẻ, cho thuê, hoặc bán những thông tin
              cá nhân, thông tin riêng  của Người sử dụng cho các tổ chức, cá nhân khác
              với bất kỳ mục đích nào trừ khi  Người sử dụng đồng ý hoặc Nhà cung cấp nhận
              được yêu cầu cung cấp thông tin từ  các cơ quan nhà nước có thẩm quyền.
            </p>
            <p>
              <strong>
                Điều 9. Chính sách bảo mật thông tin của Người sử dụng dịch vụ  mạng xã
                hội
              </strong>
            </p>
            <p>
              1. Nhà cung cấp nỗ lực  tối đa bảo mật các thông tin cá nhân, thông tin
              riêng của Người sử dụng khỏi sự  truy cập trái phép. Tuy nhiên, Nhà cung cấp
              không đảm bảo, và không cam kết sẽ  ngăn chặn được tất cả các truy cập, hoặc
              xâm nhập, sử dụng thông tin cá nhân  trái phép nằm ngoài khả năng kiểm soát
              của Nhà cung cấp. Do vậy, Nhà cung cấp sẽ  không chịu trách nhiệm dưới bất
              kỳ hình thức nào đối với bất kỳ khiếu nại,  tranh chấp hoặc thiệt hại nào
              phát sinh từ hoặc liên quan đến việc truy cập,  xâm nhập, sử dụng thông tin
              trái phép vượt khỏi mức kiểm soát như trên.
            </p>
            <p>
              2. Nhà cung cấp thực  hiện biện pháp bảo mật thông tin cá nhân của Người sử
              dụng bằng cách:
            </p>
            <p>
              a) Giới hạn thông tin  truy cập cá nhân;
            </p>
            <p>
              b) Sử dụng sản phẩm  công nghệ để ngăn chặn truy cập máy tính trái phép;
            </p>
            <p>
              c) Xoá thông tin cá  nhân của Người sử dụng khi thông tin không còn cần
              thiết cho mục đích lưu trữ  hồ sơ của mạng xã hội.
            </p>
            <p>
              3. Nếu Người sử dụng  cho rằng bảo mật của mình bị xâm phạm hay xâm nhập do
              tình trạng không được đảm  bảo bảo mật an toàn trên hệ thống bảo mật của
              mạng xã hội, Người sử dụng có thể  liên hệ với Ban quản trị Mạng xã hội để
              phản ánh và được giải quyết vấn đề.
            </p>
            <p>
              4. Nhà cung cấp có  toàn quyền chủ động chỉnh sửa chính sách bảo mật thông
              tin trên mạng xã hội vào  bất kỳ thời điểm nào khi cần thiết, hoặc theo quy
              định của pháp luật, nhằm đảm  bảo hoạt động tối ưu của mạng xã hội, và đảm
              bảo nghĩa vụ tôn trọng pháp luật  luôn được thực thi tuyệt đối. Mọi nội dung
              của chính sách bảo mật thông tin, và  các sửa đổi, bổ sung đối với chính
              sách này sẽ luôn được cập nhật và công bố  trên mạng xã hội, và sẽ được ghi
              ngày sửa đổi, cập nhật để Người sử dụng dịch  vụ có thể nhận biết được nội
              dung mới nhất.
            </p>
            <p>
              <strong>Điều 10. Chính sách quyền riêng tư</strong>
            </p>
            <p>
              1. Người sử dụng có  quyền giữ bí mật và quyền quyết định tiết lộ thông tin
              của mình bao gồm thông  tin cá nhân, thông tin riêng và các thông tin có
              liên quan khác được cung cấp,  trao đổi trên mạng xã hội; đồng thời các
              thông tin trên của Người sử dụng sẽ  được Nhà cung cấp bảo vệ khỏi sự truy
              cập, thu giữ, kiểm soát bất hợp pháp và  chưa được phép của Người sử dụng
              trừ trường hợp có yêu cầu của cơ quan nhà nước  có thẩm quyền.
            </p>
            <p>
              2. Chỉ Người sử dụng  có quyền truy cập (bao gồm tạo, xem, chỉnh sửa, xóa)
              và kiểm soát thông tin cá  nhân, thông tin riêng và các thông tin có liên
              quan khác mà Người sử dụng cung  cấp, chia sẻ, trao đổi trên mạng xã hội;
              đồng thời có quyền quyết định việc cho  phép đối tượng nào được tìm kiếm,
              xem, chia sẻ, trao đổi về các thông tin trên  của mình trên mạng xã hội.
            </p>
            <p>
              3. Trường hợp Người sử  dụng đăng ký sử dụng dịch vụ của bên thứ ba trên
              mạng xã hội, Người sử dụng  đồng ý cung cấp thông tin cá nhân, thông tin
              riêng và các thông tin có liên  quan khác cho bên thứ ba thì phải tự chịu
              trách nhiệm về sự bảo mật cho các  thông tin mà mình cung cấp. Nhà cung cấp
              không có nghĩa vụ đảm bảo tính an  toàn, riêng tư cho thông tin cá nhân của
              Người sử dụng trong trường hợp này.
            </p>
            <p>
              <strong>
                Điều 11. Sửa đổi và ngưng cung cấp dịch vụ trên trang mạng xã  hội
              </strong>
            </p>
            <p>
              1. Nhà cung cấp liên tục thay đổi và cải thiện dịch vụ mạng xã  hội ngày một
              chất lượng hơn cho Người sử dụng. Do đó, Nhà cung cấp có thể, cho  dù có
              hoặc không có thông báo, sửa đổi hoặc ngừng cung cấp dịch vụ cho Người sử
              dụng vào bất kỳ thời điểm nào. Người sử dụng đồng ý rằng Nhà cung cấp không
              có  nghĩa vụ dưới bất kỳ hình thức nào đối với Người sử dụng hoặc bất kỳ bên
              thứ <br/>
              ba nào về việc điều chỉnh, tạm ngừng hoặc ngừng cung cấp dịch vụ trên mạng
              xã  hội.
            </p>
            <p>
              2. Nhà cung cấp có  thể, tùy từng trường hợp cụ thể và không cần báo trước,
              chấm dứt ngay lập tức  điều kiện, điều khoản được thiết lập theo thoả thuận
              này và chấm dứt quyền sử  dụng dịch vụ mạng xã hội của Người sử dụng. Nguyên
              nhân của việc chấm dứt này  bao gồm nhưng không giới hạn ở sự vi phạm hoặc
              vi phạm được dự liệu đối với  thỏa thuận hoặc các hướng dẫn của chính quyền
              hoặc luật áp dụng khác; theo yêu  cầu bởi việc thực thi pháp luật hoặc bởi
              các cơ quan có thẩm quyền khác; không  tiếp tục hoặc thay đổi cơ bản đối với
              dịch vụ mạng xã hội; các vấn đề không  mong muốn về kỹ thuật hoặc an ninh;
              tài khoản không hoạt động trong thời gian  dài; Nhà cung cấp có cơ sở hợp lý
              để tin rằng Người sử dụng đang sử dụng hoặc  dự định sử dụng dịch vụ mạng xã
              hội để tham gia các hoạt động lừa đảo hoặc bất  hợp pháp; Người sử dụng
              không thanh toán các khoản phí dịch vụ nào mà Người sử  dụng phải trả...
            </p>
            <p>
              <strong>Điều 12. Sửa đổi thỏa thuận</strong>
            </p>
            <p>
              Nhà cung cấp có thể  (nhưng không là nghĩa vụ) sửa đổi nội dung thỏa thuận
              khi xét thấy cần thiết và  phù hợp với quy định của pháp luật bất cứ lúc
              nào; đồng thời cung cấp cho Người  sử dụng các thông báo, bao gồm cả các
              thông báo về các thay đổi liên quan tới  Thỏa thuận thông qua thư điện tử,
              thư thông thường hoặc đăng trên mạng xã hội.  Người sử dụng đồng ý rằng các
              thông báo bằng thư điện tử hoặc thư thường đó, và  việc hiển thị thông báo
              và các đường liên kết tới các thông báo cho Người sử  dụng trên mạng xã hội
              sẽ được coi là thông báo đầy đủ.
            </p>
            <p>
              <strong>Điều 13. Giới hạn và Miễn trách nhiệm pháp lý</strong>
            </p>
            <p>
              1. Nhà cung cấp khẳng  định, việc đăng tải nội dung, chia sẻ các thông tin
              trên mạng xã hội hoàn toàn  xuất phát từ ý chí và quan điểm cá nhân của
              Người sử dụng. Nhà cung cấp tạo môi  trường cho Người sử dụng giao lưu, chia
              sẻ thông tin với nhau nhưng không chịu  trách nhiệm với bất cứ thông tin
              không đúng hoặc không chính xác nào trong các  nội dung được đăng tải bởi
              Người sử dụng.
            </p>
            <p>
              2. Nhà cung cấp không  chịu trách nhiệm về hậu quả có liên quan trực tiếp
              hoặc  gián tiếp đến  những gì Người sử dụng thực hiện trên mạng xã hội.
              Người sử dụng có trách nhiệm  đánh giá và chấp nhận tất cả các nguy cơ liên
              quan đến việc sử dụng bất kỳ nội  dung nào trong bài viết hoặc bình luận bao
              gồm tính chính xác, độ hoàn thiện,  hoặc mức độ hữu ích của các nội dung
              này.
            </p>
            <p>
              3. Trong trường hợp có  tranh chấp phát sinh từ hành động, nội dung hoặc
              thông tin của Người sử dụng  trên mạng xã hội, Người sử dụng đồng ý giữ cho
              Nhà cung cấp và các công ty mẹ,  công ty con, các công ty liên kết và các
              công ty liên quan, các tổ chức liên  kết, các viên chức, các đại diện, các
              đối tác, nhà cung cấp, nhân viên của mạng  xã hội, được miễn trừ trách nhiệm
              đối với các yêu cầu bồi thường, bao gồm các  khoản phí luật sư hợp lý, của
              bất kỳ bên thứ ba nào.
            </p>
            <p>
              4. Người sử dụng dịch  vụ phải hoàn toàn chịu trách nhiệm pháp lý về tất cả
              các nội dung thông tin và  hình ảnh, video clip được đăng trên mạng xã hội.
              Nhà cung cấp hoàn toàn không  chịu trách nhiệm về những hành vi vi phạm pháp
              luật của nội dung tin đăng, hay  các vi phạm về quyền tác giả, quyền sở hữu
              trí tuệ liên quan đến nội dung, hình  ảnh được đăng tải trên mạng xã hội.
            </p>
            <p>
              Người sử dụng có hành  vi vi phạm phải bồi thường, và bồi hoàn cho Nhà cung
              cấp toàn bộ các tổn thất,  chi phí (bao gồm chi phí luật sư và tòa án, nếu
              có) mà Nhà cung cấp có thể đã  phải gánh chịu do có hậu quả, tranh chấp phát
              sinh từ bất kỳ vi phạm nào được  hình thành do hành vi, lỗi của Người sử
              dụng dịch vụ đó.
            </p>
            <p>
              5. Nếu xảy ra bất kỳ  khiếu nại, tranh chấp nào giữa Người sử dụng với người
              có chủ quyền liên quan  đến nội dung đã đăng tải trên mạng xã hội thì Người
              sử dụng đó phải chủ động  giải quyết, dàn xếp với người chủ quyền có liên
              quan, và tự chịu trách nhiệm  trong việc giải quyết khiếu nại, và phải chịu
              trách nhiệm chi trả cho bất kỳ  thiệt hại phát sinh do bản quyền, thương
              hiệu, và hành vi xâm phạm sở hữu trí  tuệ, để đảm bảo hoạt động kinh doanh
              của Nhà cung cấp không bị ảnh hưởng.
            </p>
            <p>
              <strong>Điều 14. Điều khoản áp dụng</strong>
            </p>
            <p>
              Thỏa thuận này có hiệu  lực kể từ ngày Người sử dụng đồng ý sử dụng dịch vụ
              mạng xã hội của MobiLearn bằng  cách hoàn thành việc đăng ký tài khoản mạng
              xã hội; làm cơ sở ràng buộc trách  nhiệm của Người sử dụng với Nhà cung cấp;
              quy định các điều kiện mà Người sử  dụng phải tuân thủ, và sẽ được áp dụng
              đối với tất cả các đối tượng là cá nhân,  tổ chức, doanh nghiệp sử dụng dịch
              vụ trên mạng xã hội.
            </p>
            <p>
              <strong>Điều 15. Điều khoản cam kết</strong>
            </p>
            <p>
              Công ty Cổ Phần  DataNova Việt Nam, cùng các tổ chức, cá nhân, doanh nghiệp
              sử dụng các dịch vụ  được cung cấp trên mạng xã hội cam kết tuân thủ các quy
              định và các điều khoản  trong thỏa thuận này.
            </p>
            <p>
              Mọi sự vi phạm thỏa  thuận này đều sẽ phải chịu trách nhiệm trước Công ty Cổ
              Phần DataNova Việt Nam  và pháp luật Việt Nam.
            </p>
            <p>
              Mọi thông tin liên  lạc, trao đổi, xin gửi về:
            </p>
            <p>
              Công ty Cổ Phần  DataNova Việt Nam
            </p>
            <p>
              Địa chỉ: Tầng M1, tòa  nhà Elcom ngõ 15 phố Duy Tân, Phường Dịch Vọng Hậu,
              Quận Cầu Giấy, Thành phố Hà  Nội, Việt Nam
            </p>
            <p>
              Điện thoại: 02438359359
            </p>
            <p>
              Email: colearn.telco@gmail.com
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import Resource from '~/common/api/resource'
import TravisAes from '~/common/utils/TravisAes'

const metaResource = new Resource('config/seo')
const sendEmail = new Resource('user/sendEmail')
export default {
  layout: 'mlearn',
  async asyncData ({
    isDev,
    route,
    store,
    env,
    params,
    query,
    req,
    res,
    redirect,
    error
  }) {
    const tabActive = parseInt(query.tab || 0)
    const meta = await metaResource.list({ path: route.path })
    const metaSeo = meta.data
    const structuredData = {}
    return {
      metaSeo,
      structuredData,
      tabActive
    }
  },
  data () {
    return {
      form: {
        name: '',
        email: '',
        phone: '',
        description: ''
      }
    }
  },
  head () {
    if (!this.metaSeo) {
      return {}
    }
    return {
      title: (this.metaSeo?.title || ''),
      meta: [
        {
          hid: 'title',
          name: 'title',
          content: (this.metaSeo?.title || '')
        },
        {
          hid: 'description',
          name: 'description',
          content: (this.metaSeo?.description || '')
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: (this.metaSeo?.keyword || '')
        },
        {
          hid: 'og:type',
          property: 'og:type',
          content: 'website'
        },
        {
          hid: 'og:title',
          property: 'og:title',
          content: (this.metaSeo?.title || '')
        },
        {
          hid: 'og:url',
          property: 'og:url',
          content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath)
        },
        {
          hid: 'og:description',
          property: 'og:description',
          content: (this.metaSeo?.description || '')
        },
        {
          hid: 'og:image',
          property: 'og:image',
          content: (this.metaSeo?.image || '')
        },
        {
          hid: 'twitter:card',
          property: 'twitter:card',
          content: 'summary_large_image'
        },
        {
          hid: 'twitter:title',
          property: 'twitter:title',
          content: (this.metaSeo?.title || '')
        },
        {
          hid: 'twitter:url',
          property: 'twitter:url',
          content: (process.env.NUXT_ENV_WEB_URL + this.$route.fullPath)
        },
        {
          hid: 'twitter:description',
          property: 'twitter:description',
          content: (this.metaSeo?.description || '')
        },
        {
          hid: 'twitter:image',
          property: 'twitter:image',
          content: (this.metaSeo?.image || '')
        }
      ],
      link: [
        {
          rel: 'canonical',
          href: process.env.NUXT_ENV_WEB_URL + this.$route.fullPath
        }
      ],
      script: [{
        type: 'application/ld+json',
        json: this.structuredData
      }]
    }
  },
  computed: {
    ...mapState([
      'user'
    ])
  },
  watch: {
    $route (newValue, oldValue) {
      this.tabActive = parseInt(this.$route.query.tab || 0)
    }
  },
  created () {
    if (this.user) {
      this.form.name = this.user.fullName
      this.form.email = this.user.email || ''
      this.form.phone = this.user.mobile || ''
    }
  },
  methods: {
    sendIntroduce () {
      this.form.name = this.form.name.trim()
      this.form.email = this.form.email.trim()
      this.form.phone = this.form.phone.trim()
      this.form.description = this.form.description.trim()
      if (!this.form.name) {
        this.$notify({
          message: 'Tên của bạn không được để trống',
          type: 'error'
        })
        return
      }
      if (this.form.name.length > 200) {
        this.$notify({
          message: 'Họ tên không dài quá 200 ký tự',
          type: 'error'
        })
        return
      }
      if (!this.form.email) {
        this.$notify({
          message: 'Địa chỉ email không được để trống',
          type: 'error'
        })
        return
      }
      if (!this.$helper.validEmail(this.form.email)) {
        this.$notify({
          message: 'Địa chỉ email không đúng định dạng',
          type: 'error'
        })
        return
      }
      if (!this.form.phone) {
        this.$notify({
          message: 'Số điện thoại không được để trống',
          type: 'error'
        })
        return
      }
      if (!this.$helper.validPhone(this.form.phone)) {
        this.$notify({
          message: 'Số điện thoại không đúng định dạng',
          type: 'error'
        })
        return
      }
      if (!this.form.description) {
        this.$notify({
          message: 'Vui lòng nhập nội dung cần tư vấn',
          type: 'error'
        })
        return
      }

      if (this.form.description.length > 1000) {
        this.$notify({
          message: 'Nội dung không dài quá 1000 ký tự',
          type: 'error'
        })
        return
      }
      const aes = new TravisAes()
      // let content = 'Tên: ' + this.form.name + ', số điện thoại: ' + this.form.phone + ', email:' + this.form.email +', nội dung: ' + this.form.description;
      const content = '<html><body><b>Tên: </b><span>' + this.form.name + '</span><br><b>Số điện thoại: </b><span>' + this.form.phone + '</span><br><b>Email: </b><span>' + this.form.email + '</span><br><b>Nội dung: </b><span>' + this.form.description + '</span></body></html>'
      const emailTo = 'support@mobilearn.vn'
      const title = 'Thông tin liên hệ'
      const plainText = emailTo + title + content
      const sign = aes.encrypt(process.env.NUXT_ENV_PASSWORD_ENCRYPT, plainText)
      const user = {
        emailTo,
        title,
        content,
        sign
      }
      sendEmail.store(user)
        .then((response) => {
          if (response.status === 200) {
            this.$notify({
              title: 'Thành công',
              message: 'Liên hệ thành công! Chúng tôi sẽ gửi lại email cho bạn sớm nhất',
              type: 'success'
            })
            this.form = {
              name: '',
              email: '',
              phone: '',
              description: ''
            }
          }
        })
        .catch(() => {
          this.$notify.error({
            title: 'Có lỗi',
            message: 'Có lỗi xảy ra vui lòng thử lại sau!'
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.ml-gioi-thieu {
  .form-content {
    background: var(--white);
    box-shadow: 0px 2px 4px rgba(202, 202, 202, 0.34);
    border-radius: 8px;
    padding: 24px;
    @media (max-width: 576px) {
      box-shadow: none;
      border-radius: 0;
      padding: 16px;
    }

    .header {
      border-bottom: 3px solid #EBEBEB;
      @media (max-width: 576px) {
      }

      span {
        font-weight: 500;
        cursor: pointer;
        color: #65676B;
        font-size: 16px;
        margin-right: 40px;
        padding-bottom: 5px;
        @media (max-width: 576px) {
          font-size: 14px;
          margin-right: 20px;
        }

        &:last-child {
          margin-right: 0;
        }

        &.active {
          position: relative;

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background: var(--primary);
            height: 3px;
            width: 20px;
            @media (max-width: 576px) {
              height: 2px;
              width: 30px;
            }
          }
        }
      }
    }

    .content {
      > h4 {
        font-weight: bold;
        margin: 0;
        font-size: 24px;
        padding: 40px 0 24px;
        @media (max-width: 576px) {
          font-size: 16px;
          padding: 20px 0 16px;
        }
      }

      .form-contact {
        background: #FAFAFA;
        border: 1px solid #EBEBEB;
        border-radius: 16px;
        padding: 24px;
        margin-top: 40px;
        @media (max-width: 576px) {
          border-radius: 10px;
          padding: 16px;
          margin-top: 20px;
        }

        > h4 {
          font-weight: bold;
          margin-bottom: 0;
          font-size: 24px;
          padding: 0 0 24px;
          @media (max-width: 576px) {
            font-size: 16px;
            padding: 0 0 16px;
          }
        }

        .group-item {
          margin-bottom: 16px;
          @media (max-width: 576px) {
            margin-bottom: 10px;
          }

          .label {
            font-size: 12px;
          }

          input, textarea {
            background: var(--white);
            border: 1px solid #CED4DA !important;
            border-radius: 8px;
            width: 100%;
            font-size: 14px;
            resize: none;
            padding: 10px 16px;
            @media (max-width: 576px) {
              padding: 8px 10px;
            }

            &::placeholder {
              opacity: .7;
            }
          }

          button {
            background: var(--primary);
            border-radius: 5px;
            color: var(--white);
            padding: 10px 40px;
            @media (max-width: 576px) {
              padding: 8px 30px;
            }
          }
        }

        .form-data {
          margin-bottom: 16px;
          @media (max-width: 576px) {
            margin-bottom: 10px;
          }

          .icon {
            margin-right: 16px;
            @media (max-width: 576px) {
              margin-right: 10px;
            }
          }

          .title {
            font-size: 16px;
            opacity: 0.7;
            @media (max-width: 576px) {
              font-size: 14px;
            }
          }

          .description {
            font-weight: 500;
            font-size: 16px;
            @media (max-width: 576px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  table td {
    border: 1px solid black;
    padding: 0 15px;
  }
}
</style>
